<template>
  <section>
    <div class="hero_title_1">{{ $t('Billing.AutomaticPayments') }}</div>

    <div v-if="RPPShowEdit">
      <p>{{ $t('Shared.Element.RPPAlreadySignedUp') }}</p>
      <p class="mb-1"><strong>{{ $t('Shared.Element.RPPStatus') }}: </strong>{{ $t(RPPStatus) }}</p>
      <p class="mb-1"><strong>{{ $t('Shared.Element.RPPPullDate') }}: </strong>{{ RPPPullDate }}</p>
      <p class="mb-1"><strong>{{ $t('Shared.Element.PaymentAmount') }}: </strong>{{ RPPAmountDue }}</p>
      <p class="mb-1"><strong>{{ RPPFullCardType }} {{ $t('Shared.Element.CCTypeLast4') }}: </strong>{{ RPPCCNumber4 }}</p>
      <p class="mb-1"><strong>{{ $t('Shared.Element.ExpirationDate') }}: </strong>{{ RPPExpDate }}</p>
    </div>

    <div v-if="RPPShowSignUp">
      <p><strong>{{ $t('Shared.Element.RPPSignUpMessageOne') }}</strong></p>
      <p>{{ $t('Shared.Element.RPPSignUpMessageTwo') }}</p>
    </div>

    <div v-if="!(RPPShowEdit === false && RPPShowSignUp === false)" class="mt-3 pb-4">
      <b-button v-if="!RPPDisableButton && allowPolicyPayment" @click.prevent="goToRPPForm" type="button" variant="primary" size="lg" class="col-md-7 font-16"> {{ $t(RPPButtonText) }}</b-button>
      <b-button v-else type="button" variant="secondary" size="lg" class="col-md-7 font-16" disabled><font-awesome-icon icon="ban" /> {{ $t(RPPButtonText) }}</b-button>
      <br><small>{{ $t(RPPToolTipMessage) }}</small>
    </div>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AutomaticPayments',
  props: ['RPPShowSignUp', 'RPPShowEdit', 'allowPolicyPayment'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('billingAndPayments', [
      'RPPDisableButton',
      'RPPStatus',
      'RPPButtonText',
      'RPPPullDate',
      'RPPAmountDue',
      'RPPCCNumber4',
      'RPPExpDate',
      'RPPFullCardType',
      'RPPToolTipMessage',
      'RPPGoogleAnalyticsLabel'
    ]),
    driverPolicyNumber () {
      return this.$store.getters['account/selectedPolicyNumber']
    }
  },
  methods: {
    goToRPPForm () {
      this.$emit('loading', { loading: true, error: null })

      this.$ga.event('OCP', 'click', this.RPPGoogleAnalyticsLabel)

      this.$store.dispatch('RPP/getRPPData', this.driverPolicyNumber)
        .then(resp => {
          this.$emit('loading', { loading: false, error: null })
          this.redirectToRPP(resp)
        })
        .catch(err => {
          this.$emit('loading', { loading: false, error: err })
        })
    },
    redirectToRPP (vendor) {
      let RPPForm = ''
      if (vendor === 'oneInc') {
        RPPForm = 'RPPOneInc'
      } else if (vendor === 'cardConnect') {
        RPPForm = 'RPPCardConnect'
      } else if (vendor === 'stripe') {
        RPPForm = 'RPPStripe'
      } else {
        this.$router.push({ name: 'login', params: { externalError: 'Shared.Element.Errors.500' } })
      }

      this.$router.push({ name: RPPForm })
    }
  }
}
</script>
<style scoped>

</style>
