import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.join.js";
import Axios from 'axios';
import { setUser, endSession } from '@/config/raygun';
var state = {
  status: '',
  loggedIn: localStorage.getItem('loggedIn') || ''
};
var getters = {
  isLoggedIn: function isLoggedIn(state) {
    return !!state.loggedIn;
  },
  authStatus: function authStatus(state) {
    return state.status;
  }
};
var mutations = {
  auth_request: function auth_request(state) {
    state.status = 'loading';
  },
  auth_success: function auth_success(state) {
    state.status = 'success';
    state.loggedIn = 'yes';
  },
  auth_error: function auth_error(state) {
    state.status = 'error';
  },
  logout: function logout(state) {
    state.status = '';
    state.loggedIn = '';
  }
};
var actions = {
  login: function login(_ref, user) {
    var commit = _ref.commit,
        dispatch = _ref.dispatch;
    commit('auth_request');
    var data = new FormData();
    data.append('email', user.email);
    data.append('password', user.password);
    data.append('currentLocale', user.currentLocale);
    return new Promise(function (resolve, reject) {
      Axios.post('/api/pub/auth/login', data).then(function (resp) {
        var userData = resp.data.data.account;
        var userInfo = {
          accountKey: userData.accountKey,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email
        };
        setUser(userInfo);
        localStorage.setItem('loggedIn', 'yes');
        commit('auth_success');
        dispatch('account/setPolicyNumberArray', userData.policies, {
          root: true
        });
        dispatch('account/setSelectedPolicyNumber', userData.policies[0], {
          root: true
        });
        dispatch('account/setUserInfo', userInfo, {
          root: true
        });
        resolve(resp.statusText);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  logout: function logout(_ref2) {
    var commit = _ref2.commit,
        dispatch = _ref2.dispatch;
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/user/logout').then(function () {
        endSession();
        dispatch('clearSession');
        commit('logout');
        localStorage.removeItem('loggedIn');
        resolve();
      }).catch(function (err) {
        endSession();
        dispatch('clearSession');
        commit('logout');
        localStorage.removeItem('loggedIn');
        reject(err);
      });
    });
  },
  clearSession: function clearSession(_ref3) {
    var commit = _ref3.commit;
    commit('policy/set_doesPolicyInfoExist_to_false', null, {
      root: true
    });
    commit('policy/clear_policy_module_state', null, {
      root: true
    });
    commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false', null, {
      root: true
    });
    commit('billingAndPayments/clear_billingAndPayments_module_state', null, {
      root: true
    });
    commit('communication/clear_communication_module_state', null, {
      root: true
    });
    commit('profile/clear_profile_module_state', null, {
      root: true
    });
    commit('documents/set_doesDocumentsInfoExist_to_false', null, {
      root: true
    });
    commit('documents/clear_documents_module_state', null, {
      root: true
    });
    commit('producer/set_doesProducerInfoExist_to_false', null, {
      root: true
    });
    commit('producer/clear_producer_module_state', null, {
      root: true
    });
    commit('RPP/set_doesRPPInfoExist_to_false', null, {
      root: true
    });
    commit('RPP/clear_RPP_module_state', null, {
      root: true
    });
    commit('account/clear_account_module_state', null, {
      root: true
    });
  },
  register: function register(_ref4, newUserData) {
    var commit = _ref4.commit;
    var data = new FormData();
    Object.keys(newUserData).map(function (key) {
      data.append(key, newUserData[key]);
    });
    return new Promise(function (resolve, reject) {
      Axios.post('/api/pub/auth/register', data).then(function (resp) {
        resolve(resp);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  registerConfirm: function registerConfirm(_ref5, registrationKeysAndLocale) {
    var commit = _ref5.commit;
    var params = Object.keys(registrationKeysAndLocale).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(registrationKeysAndLocale[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/pub/auth/register-confirm', params).then(function (resp) {
        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  getVehicleMakes: function getVehicleMakes(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      Axios.get('/api/pub/auth/register-vehicle-list').then(function (resp) {
        var vehicleMakes = resp.data.data.makes;
        resolve(vehicleMakes);
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  passwordReset: function passwordReset(_ref7, emailAndLocale) {
    var commit = _ref7.commit;
    var params = Object.keys(emailAndLocale).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(emailAndLocale[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/pub/auth/password-reset', params).then(function (resp) {
        resolve(resp);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  passwordResetValidate: function passwordResetValidate(_ref8, passwordResetKeysAndLocale) {
    var commit = _ref8.commit;
    return new Promise(function (resolve, reject) {
      Axios.get('/api/pub/auth/password-reset-validate', {
        params: {
          accountKey: passwordResetKeysAndLocale.accountKey,
          passwordResetKey: passwordResetKeysAndLocale.passwordResetKey,
          currentLocale: passwordResetKeysAndLocale.currentLocale
        }
      }).then(function (resp) {
        resolve(resp);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  passwordResetConfirm: function passwordResetConfirm(_ref9, pwResetConfirmKeysAndLocale) {
    var commit = _ref9.commit;
    var params = Object.keys(pwResetConfirmKeysAndLocale).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(pwResetConfirmKeysAndLocale[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/pub/auth/password-reset-confirm', params).then(function (resp) {
        resolve(resp);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};