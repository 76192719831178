import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
export default {
  name: 'RegistrationConfirmPage',
  data: function data() {
    return {
      loading: false,
      accountKey: this.$route.query.accountKey,
      confirmationKey: this.$route.query.confirmationKey
    };
  },
  computed: {
    locale: function locale() {
      return this.$i18n.locale + '_US';
    }
  },
  mounted: function mounted() {
    this.loading = true;
    this.callAPIRegisterConfirm();
  },
  methods: {
    callAPIRegisterConfirm: function callAPIRegisterConfirm() {
      var _this = this;

      var accountKey = this.accountKey;
      var confirmationKey = this.confirmationKey;
      var currentLocale = this.locale;

      if (accountKey && confirmationKey) {
        this.$store.dispatch('authentication/registerConfirm', {
          accountKey: accountKey,
          confirmationKey: confirmationKey,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this.$router.push({
            name: 'login',
            params: {
              externalSuccess: 'Register.HeaderComplete'
            }
          });
        }).catch(function (err) {
          _this.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        }).finally(function () {
          _this.loading = false;
        });
      } else {
        this.loading = false;
        this.$router.push({
          name: 'login',
          params: {
            externalError: 'Register.Errors.InvalidLink'
          }
        });
      }
    }
  }
};