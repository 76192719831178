//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, email } from 'vuelidate/lib/validators';
export default {
  name: 'PasswordResetPage',
  data: function data() {
    return {
      errors: [],
      loading: false,
      showForm: true,
      alertError: false,
      alertSuccess: false,
      alertSuccessMessage: '',
      email: ''
    };
  },
  computed: {
    locale: function locale() {
      return this.$i18n.locale + '_US';
    }
  },
  validations: {
    email: {
      required: required,
      email: email
    }
  },
  methods: {
    passwordReset: function passwordReset() {
      var _this = this;

      this.alertError = false;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.errors = [];
        if (!this.$v.email.required || !this.$v.email.email) this.errors.push('Shared.Element.Errors.Email');
        this.alertError = true;
      } else {
        this.loading = true;
        var _email = this.email;
        var currentLocale = this.locale;
        this.$store.dispatch('authentication/passwordReset', {
          email: _email,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this.loading = false;
          _this.alertSuccessMessage = 'Reset.DirectionsComplete';
          _this.alertSuccess = true;
          _this.showForm = false;
        }).catch(function (err) {
          if (err.response.status === 403) {
            _this.$router.push({
              name: 'login',
              params: {
                externalError: err.errorArray[0]
              }
            });
          } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this.errors = err.errorArray;
            _this.loading = false;
            _this.alertError = true;
          }
        });
      }
    },
    dismissAlert: function dismissAlert() {
      this.$v.$reset();
      this.alertError = false;
    }
  }
};