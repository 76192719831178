import Vue from 'vue';
import Vuex from 'vuex';
import siteConfig from './modules/pub/siteConfig';
import authentication from './modules/pub/authentication';
import account from './modules/account/account';
import communication from './modules/account/communication';
import producer from './modules/account/producer';
import policy from './modules/account/policy';
import billingAndPayments from './modules/account/billingAndPayments';
import RPP from './modules/account/RPP';
import documents from './modules/account/documents';
import profile from './modules/account/profile';
import esign from './modules/pub/esign';
import EDelivery from './modules/pub/EDelivery';
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    siteConfig: siteConfig,
    authentication: authentication,
    account: account,
    communication: communication,
    producer: producer,
    policy: policy,
    billingAndPayments: billingAndPayments,
    RPP: RPP,
    documents: documents,
    profile: profile,
    esign: esign,
    EDelivery: EDelivery
  }
});