<template>
  <section>
    <div v-if="error" class="text-center">
      <h1>{{status}} {{ $t('Shared.Element.Error') }}</h1>
      <p>{{ $t(error) }}</p>
    </div>

    <div v-else>

      <div v-if="loading" class="loading">
        <div class="overlay">
          <b-spinner variant="primary" label="Spinning" class="uil-ring-css"></b-spinner>
        </div>
      </div>

      <div v-if="billingAndPaymentsInfoExist">

        <div class="row">
          <div class="col">
            <b-alert :show="(RPPSuccessMessage ? 10 : false)" variant="success" dismissible><font-awesome-icon icon="check-circle" class="desktop-view py-0" /> {{ $t(RPPSuccessMessage) }}</b-alert>
          </div>
        </div>

        <div v-if="showAutomaticPaymentsSection" class="row mobile-view">
          <div class="col-md-12">
            <b-card no-body class="mb-1 mt-3">
              <b-card-header header-tag="header" class="p-1 " role="tab">
                <b-button size="lg" block href="#" v-b-toggle.accordion-auto variant="primary" class="text-left font-weight-bold" >{{ $t('Billing.SetupAutomaticPayment') }}
                  <span class="when-opened"><font-awesome-icon icon="angle-up" class="float-right"/></span>
                  <span class="when-closed"><font-awesome-icon icon="angle-down" class="float-right"/></span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-auto" accordion="my-accordion-auto" role="tabpanel">
                <b-card-body>
                  <div class="recycling_desktop text-center">
                    <Automatic-Payments :RPPShowSignUp="RPPShowSignUp" :RPPShowEdit="RPPShowEdit" @loading="loadRPPForm"></Automatic-Payments>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>

        <div class="row">

          <div class="col-md-6">
            <div class="white_canvas mt-4-mobile" :class="{ 'min-high-360-desktop': showAutomaticPaymentsSection }">
              <strong>{{ $t('Billing.NextPaymentDate') }} {{billingInfo.billingInfo.results.dueDate}}</strong>
              <div v-if="billingInfo.billingInfo.hasErrors">
                {{ $t('Billing.Error') }}
              </div>
              <div v-else>
                <h1 class="h_strong">{{billingInfo.billingInfo.results.amountDue}}</h1>
              </div>
              <div class="mt-5 pb-4">
                <form id="oneTimePaymentForm" :action="paymentURL" :target="dynamicTarget" method="post">
                  <input type="hidden" name="txtPolicyNum" v-model="driverPolicyNumber">
                  <input type="hidden" name="txtDateOfBirth" v-model="driverDOB">
                  <input type="hidden" name="btnFormPayment1" value="Continue">
                  <b-button v-if="allowPolicyPayment" @click="processForm" variant="primary" size="lg" class="col-md-6 font-16 desktop-view"> {{ $t('Billing.MakeOnetimePayment') }}</b-button>
                  <b-button v-else variant="secondary" size="lg" class="col-md-6 font-16 desktop-view" disabled><font-awesome-icon icon="ban" /> {{ $t('Billing.MakeOnetimePayment') }}</b-button>
                  <b-button v-if="allowPolicyPayment" @click="processForm" variant="outline-primary" size="lg" class="col-md-6 font-16 mobile-view"> {{ $t('Billing.MakeOnetimePayment') }}</b-button>
                  <b-button v-else variant="secondary" size="lg" class="col-md-6 font-16 mobile-view" disabled><font-awesome-icon icon="ban" /> {{ $t('Billing.MakeOnetimePayment') }}</b-button>
                </form>
              </div>
            </div>
          </div>
          <!-- See JS comment below for v-if -->
          <div v-if="showAutomaticPaymentsSection" class="col-md-6 desktop-view">
            <div class="recycling_desktop text-center min-high-360-desktop">
              <Automatic-Payments :allowPolicyPayment="allowPolicyPayment" :RPPShowSignUp="RPPShowSignUp" :RPPShowEdit="RPPShowEdit" @loading="loadRPPForm" @second="loadRPPForm"></Automatic-Payments>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>
</template>

<script>
import AutomaticPayments from '@/components/account/RPP/AutomaticPayments.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'billingAndPaymentsView',
  components: {
    AutomaticPayments
  },
  props: ['RPPSuccessMessage'],
  data () {
    return {
      loading: false,
      status: null,
      error: null
    }
  },
  computed: {
    ...mapGetters('billingAndPayments', [
      'billingInfo',
      'billingAndPaymentsInfoExist',
      'driverDOB',
      'RPPShowSignUp',
      'RPPShowEdit'
    ]),
    ...mapGetters('policy', [
      'allowPolicyPayment'
    ]),
    paymentURL () {
      let currentLanguage = this.$i18n.locale
      let thirdParty = this.$store.getters['siteConfig/thirdParty']
      return (currentLanguage === 'es' && thirdParty ? this.$store.getters['siteConfig/paymentURL'].concat('?lang=2') : this.$store.getters['siteConfig/paymentURL'])
    },
    dynamicTarget () {
      let thirdParty = this.$store.getters['siteConfig/thirdParty']
      return (thirdParty ? '_self' : '_blank')
    },
    driverPolicyNumber () {
      return this.$store.getters['account/selectedPolicyNumber']
    },
    showAutomaticPaymentsSection () {
      return this.RPPShowSignUp || this.RPPShowEdit
    }
  },
  created () {
    this.getPageData()
    this.$store.dispatch('account/setSelectedTabName', 'Billing.Header')
  },
  methods: {
    getPageData () {
      this.loading = true

      if (!this.billingAndPaymentsInfoExist) {
        if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
          this.callAPI()
        } else {
          this.$store.dispatch('account/refreshSessionInfo')
            .then(response => {
              this.callAPI()
            })
            .catch(err => this.handleError(err))
        }
      } else {
        this.loading = false
      }
    },
    callAPI () {
      const billingPromise = this.$store.dispatch('account/requestData', this.$store.getters['billingAndPayments/billingAndPaymentsAPIParams'])
      const producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams'])
      const policyPromise = this.$store.dispatch('account/requestData', this.$store.getters['policy/policyAPIParams'])

      Promise.all([billingPromise, producerPromise, policyPromise])
        .then(() => {
          this.loading = false
        })
        .catch(err => { this.handleError(err) })
    },
    // One time payment hidden form submission
    processForm () {
      if (this.driverPolicyNumber.length === 13 && this.driverDOB.length === 10) {
        this.$ga.event('OCP', 'click', 'Payment - Logged In')
        document.getElementById('oneTimePaymentForm').submit()
      } else {
        alert('Error!')
      }
    },
    // Emission from <Automatic-Payments/> child component
    loadRPPForm (value) {
      if (!value.error) {
        this.loading = value.loading
      } else {
        this.handleError(value.error)
      }
    },
    handleError (err) {
      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout')
          .then(() => this.$router.push({ name: 'login', params: { externalError: err.errorArray[0] } }))
      } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status
        this.error = err.errorArray[0]
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>

</style>
