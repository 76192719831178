//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { required, email } from 'vuelidate/lib/validators';
export default {
  name: 'LoginPage',
  props: {
    // Error or Success messages passed in from outside this component. eg: Registration success, unauthorized access error
    externalError: {
      type: String,
      default: null
    },
    externalSuccess: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      errors: [],
      loading: false,
      alertError: false,
      passwordState: true,
      form: {
        email: '',
        password: ''
      }
    };
  },
  computed: {
    locale: function locale() {
      return this.$i18n.locale + '_US';
    }
  },
  validations: {
    form: {
      email: {
        required: required,
        email: email
      },
      password: {
        required: required
      }
    }
  },
  methods: {
    login: function login() {
      var _this = this;

      this.alertError = false;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.validateForm();
      } else {
        this.loading = true;
        var _email = this.form.email;
        var password = this.form.password;
        var currentLocale = this.locale;
        this.$store.dispatch('authentication/login', {
          email: _email,
          password: password,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this.loading = false;

          _this.$router.push({
            name: 'policy'
          });
        }).catch(function (err) {
          _this.handleError(err);

          _this.loading = false;
        });
      }
    },
    validateForm: function validateForm() {
      this.errors = [];
      if (!this.$v.form.email.required) this.errors.push('Shared.Element.Errors.Email');
      if (!this.$v.form.email.email) this.errors.push('Shared.Element.Errors.Email');
      if (!this.$v.form.password.required) this.errors.push('Shared.Element.Errors.Password');
      this.alertError = true;
    },
    handleError: function handleError(err) {
      if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
        this.errors = err.errorArray;
        this.alertError = true;
      }
    }
  }
};