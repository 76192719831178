import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index.js';
import Account from '@/views/account/AccountBase.vue';
import Login from '@/views/pub/Login.vue';
import ESignBusinessLanding from '@/views/pub/ESignBusinessLanding';
import ESignCustomerLanding from '@/views/pub/ESignCustomerLanding';
import Policy from '@/views/account/AccountPolicy.vue';
import BillingAndPayments from '@/views/account/AccountBillingAndPayments.vue';
import Documents from '@/views/account/AccountDocuments.vue';
import Profile from '@/views/account/AccountSettings.vue';
import Communication from '@/views/account/AccountCommunicationsSettings.vue';
import Registration from '@/views/pub/Registration';
import RegistrationConfirm from '@/views/pub/RegistrationConfirm';
import PasswordReset from '@/views/pub/PasswordReset';
import PasswordResetConfirm from '@/views/pub/PasswordResetConfirm';
import PaymentGuest from '@/views/pub/PaymentGuest';
import AccountRPPCardConnect from '@/views/account/AccountRPPCardConnect.vue';
import AccountRPPStripe from '@/views/account/AccountRPPStripe.vue';
import AccountRPPOneInc from '@/views/account/AccountRPPOneInc.vue';
import Redirect404 from '@/views/404';
import Redirect500 from '@/views/500';
Vue.use(VueRouter);
var routes = [{
  path: '/',
  alias: '/login',
  name: 'login',
  component: Login,
  props: true,
  meta: {
    disallowAuthed: true,
    title: 'Shared.Meta.Title.Home'
  }
}, {
  path: '/register',
  name: 'register',
  component: Registration,
  meta: {
    disallowAuthed: true,
    title: 'Shared.Meta.Title.Register'
  }
}, {
  path: '/register-confirm',
  name: 'register-confirm',
  component: RegistrationConfirm,
  meta: {
    disallowAuthed: true,
    title: 'Shared.Meta.Title.Register'
  }
}, {
  path: '/password-reset',
  name: 'password-reset',
  component: PasswordReset,
  meta: {
    disallowAuthed: true,
    title: 'Shared.Meta.Title.PasswordReset'
  }
}, {
  path: '/password-reset-confirm',
  name: 'password-reset-confirm',
  component: PasswordResetConfirm,
  meta: {
    disallowAuthed: true,
    title: 'Shared.Meta.Title.PasswordReset'
  }
}, {
  path: '/payment-guest',
  name: 'payment-guest',
  component: PaymentGuest,
  meta: {
    disallowAuthed: true,
    title: 'Shared.Meta.Title.PaymentGuest'
  }
}, // The next two routes allowed in case user is logged in and clicks link to ESign
{
  path: '/esign',
  alias: '/main/signNow',
  name: 'esign-customer-landing',
  component: ESignCustomerLanding,
  meta: {
    title: 'Shared.Meta.Title.ESign'
  }
}, {
  path: '/esign-complete',
  alias: '/main/docusign',
  name: 'esign-business-landing',
  component: ESignBusinessLanding,
  meta: {
    title: 'Shared.Meta.Title.ESign'
  }
}, {
  path: '/account',
  component: Account,
  name: 'account',
  meta: {
    requiresAuth: true
  },
  children: [{
    path: 'policy',
    name: 'policy',
    component: Policy,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.Policy'
    }
  }, {
    path: 'billing',
    name: 'billingAndPayments',
    component: BillingAndPayments,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.Billing'
    }
  }, {
    path: 'documents',
    name: 'documents',
    component: Documents,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.Documents'
    }
  }, {
    path: 'profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.Settings'
    }
  }, {
    path: 'communication',
    name: 'communication',
    component: Communication,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.Communication'
    }
  }, {
    path: 'billing-automatic-c',
    alias: 'payments/cardconnect',
    name: 'RPPCardConnect',
    component: AccountRPPCardConnect,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.RecurringPayments'
    }
  }, {
    path: 'billing-automatic-s',
    alias: 'payments/stripe',
    name: 'RPPStripe',
    component: AccountRPPStripe,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.RecurringPayments'
    }
  }, {
    path: 'billing-automatic',
    alias: 'payments/oneinc',
    name: 'RPPOneInc',
    component: AccountRPPOneInc,
    meta: {
      requiresAuth: true,
      title: 'Shared.Meta.Title.RecurringPayments'
    }
  }]
}, {
  path: '*',
  name: '404',
  component: Redirect404,
  meta: {
    title: 'Shared.Meta.Title.404'
  }
}, {
  path: 'internal-error',
  name: '500',
  component: Redirect500,
  meta: {
    title: 'Shared.Meta.Title.500'
  }
}];
var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  // Pushing pages to top on navigation
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    };
  }
});
router.beforeEach(function (to, from, next) {
  // Disallow non-authenticated users from accessing protected routes
  if (to.matched.some(function (record) {
    return record.meta.requiresAuth;
  })) {
    if (Store.getters['authentication/isLoggedIn']) {
      next();
      return;
    }

    next({
      name: 'login'
    }); // Disallow authenticated users from accessing non-protected routes
  } else if (to.matched.some(function (record) {
    return record.meta.disallowAuthed;
  })) {
    if (!Store.getters['authentication/isLoggedIn']) {
      next();
      return;
    }

    next({
      name: 'policy'
    }); // Allow 404s to pass through
  } else {
    next();
  }
});
export default router;