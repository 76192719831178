<template>
  <div>
    <div v-if="loading" class="loading">
      <div class="overlay">
        <b-spinner variant="primary" label="Spinning" class="uil-ring-css"></b-spinner>
      </div>
    </div>
    <div v-else>
      <!--mobile store display-->
      <div class="row mobile-view">
        <div class="col-md-12">
          <b-card no-body class="mb-1 mt-3">
            <b-card-header header-tag="header" class="p-1 " role="tab">
              <b-button block href="#" v-b-toggle.accordion-s variant="primary" class="text-left font-weight-bold" ><font-awesome-icon icon="map-marker-alt"/> {{ $t('Shared.Element.MyStore') }}
                <span class="when-opened"><font-awesome-icon icon="angle-up" class="float-right"/></span>
                <span class="when-closed"><font-awesome-icon icon="angle-down" class="float-right"/></span>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-s" accordion="my-accordion-store" role="tabpanel">
              <b-card-body>
                <store-ref/>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!--/mobile store display-->
      <!--page header-->
      <div class="row page_header">
        <div class="col-md-12">
          <div class="row">
            <div class="col col-xs">
              <img src="@/assets/images/round_car_icon.svg" alt="" class="header_icon desktop-view">
              <h1>{{ $t(selectedTabName) }}</h1>
              <h3>{{selectedPolicyNumber}}</h3>
            </div>

            <div v-if="unselectedPolicies.length > 0
              && this.$route.name !== 'settings'
              && this.$route.name !== 'RPPCardconnect'
              && this.$route.name !== 'RPPStripe'" class="float-right text-right col col-xs">

              <div class="dropdown" ref="dropdownMenu">
                <button class="btn btn-outline-primary" @click.prevent="showMenu=!showMenu" tabindex="0">{{ $t('Shared.Element.ViewOtherPolicies') }} <font-awesome-icon icon="angle-down"/></button>
                <div class="dropdown-content" v-if='showMenu'>
                  <a v-for="policy in unselectedPolicies" :key="policy" @click.prevent="updateNewSelectedPolicyNumber(policy)">{{policy}}</a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <!-- /page header -->
    </div>
  </div>
</template>

<script>
import StoreRef from './TheStoreRef.vue'

export default {
  name: 'PageHeader',
  components: {
    StoreRef
  },
  data () {
    return {
      loading: false,
      showMenu: false
    }
  },
  computed: {
    storedAPIParamsString () {
      return this.$route.name + '/' + this.$route.name + 'APIParams' // Ex: 'policy/policyAPIParams'
    },
    deleteOtherVuexStatesString () {
      return this.$route.name + '/' + 'deleteNon' + (this.$route.name)[0].toUpperCase() + this.$route.name.slice(1) + 'Info'
    },
    selectedTabName () {
      return this.$store.getters['account/selectedTabName']
    },
    unselectedPolicies () {
      let policyArray = this.$store.getters['account/policyArray']
      return policyArray.filter(policy => policy !== this.$store.getters['account/selectedPolicyNumber'])
    },
    selectedPolicyNumber () {
      return this.$store.getters['account/selectedPolicyNumber']
    }
  },
  // Document listeners to close the policy dropdown when a user click off the <div>
  created () {
    document.addEventListener('click', this.documentClick)
  },
  destroyed () {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    updateNewSelectedPolicyNumber (newSelectedPolicy) {
      this.loading = true
      let fallbackCurrentPolicy = this.$store.getters['account/selectedPolicyNumber']

      this.$store.dispatch('account/setSelectedPolicyNumber', newSelectedPolicy)
        .then(() => {
          const deleteOtherVuexStatesPromise = this.$store.dispatch(this.deleteOtherVuexStatesString)
          const pageDataPromise = this.$store.dispatch('account/requestData', this.$store.getters[this.storedAPIParamsString])
          const producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams'])
          const policyPromise = this.$store.dispatch('account/requestData', this.$store.getters['policy/policyAPIParams'])

          Promise.all([deleteOtherVuexStatesPromise, pageDataPromise, producerPromise, policyPromise])
            .then(() => {
              this.showMenu = false
              this.loading = false
            })
            .catch(() => {
              this.handleError(fallbackCurrentPolicy)
            })
        })
        .catch(() => {
          this.handleError(fallbackCurrentPolicy)
        })
    },
    handleError (originalPolicy) {
      this.$store.dispatch('account/setSelectedPolicyNumber', originalPolicy)
      this.$forceUpdate()
    },
    documentClick (e) {
      let el = this.$refs.dropdownMenu
      if (el !== undefined) {
        let target = e.target
        if (el !== target && !el.contains(target)) {
          this.showMenu = false
        }
      }
    }
  }
}
</script>

<style>
</style>
