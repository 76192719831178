import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ESignCustomerLandingPage',
  data: function data() {
    return {
      // Key received from client's text, initiating ESign process.
      eSignEnvelopeQueueKey: this.$route.query.ESignEnvelopeQueueKey || this.$route.query.eSignEnvelopeQueueKey || '',
      // Data used to control template flow
      loading: false,
      error: null,
      docsAlreadySigned: false,
      docNotSignedDisplayFormBtn: false,
      // Data from getEnvelopeQueueStatus request
      appID: 0,
      phoneNumber: '',
      policyNumber: '',
      email: '',
      name: '',
      customerSigningURL: ''
    };
  },
  created: function created() {
    this.callEnvelopeQueueStatus();
  },
  methods: {
    callEnvelopeQueueStatus: function callEnvelopeQueueStatus() {
      var _this = this;

      this.loading = true;
      var eSignEnvelopeQueueKey = this.eSignEnvelopeQueueKey;

      if (eSignEnvelopeQueueKey.length < 1) {
        this.error = 'ESign.error.InvalideSignEnvelopeQueueKey'; // Invalid eSignEnvelopeQueueKey

        return;
      }

      this.$store.dispatch('esign/getEnvelopeQueueStatus', eSignEnvelopeQueueKey).then(function (resp) {
        if (resp.isValid) {
          _this.policyNumber = resp.policyNumber;
          _this.name = resp.name;
          _this.phoneNumber = resp.phoneNumber;
          _this.email = resp.email;
          _this.appID = resp.appID;
          _this.eSignEnvelopeQueueKey = resp.eSignEnvelopeQueueKey;

          if (resp.isCompleted) {
            _this.docsAlreadySigned = true;
          } else {
            _this.docNotSignedDisplayFormBtn = true;
          }
        } else {
          _this.error = 'ESign.error.DataInvalid'; // API response info.isValid === false
        }
      }).catch(function (err) {
        _this.handleError(err);
      }).finally(function () {
        _this.loading = false;
      });
    },
    callGetSigningURL: function callGetSigningURL() {
      var _this2 = this;

      this.loading = true;
      var appID = this.appID;
      var phoneNumber = this.phoneNumber;
      var eSignEnvelopeQueueKey = this.eSignEnvelopeQueueKey;

      if (typeof appID !== 'number' || appID < 1 || phoneNumber.length !== 12) {
        this.error = 'ESign.error.AppIDorPhoneNotValid'; // Err: AppID or PhoneNumber is invalid

        return;
      }

      if (eSignEnvelopeQueueKey.length < 1) {
        this.error = 'ESign.error.InvalideSignEnvelopeQueueKey'; // Err: envelope queue key does not exist

        return;
      }

      this.$store.dispatch('esign/getSigningURL', {
        appID: appID,
        phoneNumber: phoneNumber,
        eSignEnvelopeQueueKey: eSignEnvelopeQueueKey
      }).then(function (customerSigningURL) {
        window.location.href = customerSigningURL;
      }).catch(function (err) {
        return _this2.handleError(err);
      }).finally(function () {
        _this2.loading = false;
      });
    },
    handleError: function handleError(err) {
      if (err.errorArray) {
        this.error = err.errorArray[0];
      } else {
        this.error = 'Shared.Element.Errors.500'; // Unknown Axios request issue
      }
    }
  }
};