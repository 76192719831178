import Axios from 'axios'

const state = {
  doesSiteConfigInfoExist: false,
  // Increment this variable to update public asset files
  assetVersion: '3',
  domainMap: {
    'aains.portal.localhost': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.dev-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.devupgrade-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.test-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.testupgrade-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.uat-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.uatupgrade-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c-test',
    'cp-aains.demo-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c',
    'cp-aains.demoupgrade-online.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c',
    'customerupgrade.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c',
    'customer.aains.com': '1a8f7d16-95dd-4bcc-b39c-3c772a833c3c'
  },
  siteConfig: {
    'GATrackingID': '',
    'RPPUserID': 0,
    'thirdParty': 0,
    'paymentURL': '',
    'logo': '',
    'mainSiteURL': '',
    'emailFromAddress': '',
    'termsURL': '',
    'clientKey': '',
    'name': '',
    'code': '',
    'FAQURL': '',
    'privacyURL': '',
    'mainPhone': '',
    'examplePolicyPrefix': '',
    'companyName': '',
    'copyright': ''
  }
}

const getters = {
  getSiteConfigurations: state => state.siteConfig,
  siteConfigInfoExists: state => state.doesSiteConfigInfoExist,
  GATrackingID: state => state.siteConfig.GATrackingID,
  RPPUserID: state => state.siteConfig.RPPUserID,
  mainSiteURL: state => state.siteConfig.mainSiteURL,
  termsURL: state => state.siteConfig.termsURL,
  privacyURL: state => state.siteConfig.privacyURL,
  logo: state => state.siteConfig.logo,
  paymentURL: state => state.siteConfig.paymentURL,
  thirdParty: state => state.siteConfig.thirdParty,
  address: state => state.siteConfig.address,
  cityStateZip: state => state.siteConfig.cityStateZip,
  mainPhone: state => state.siteConfig.mainPhone,
  mainPhoneDigits: state => state.siteConfig.mainPhone.replace(/\D/g, ''),
  examplePolicyPrefix: state => state.siteConfig.examplePolicyPrefix,
  companyName: state => state.siteConfig.companyName,
  copyright: state => state.siteConfig.copyright,
  customCSSHref: state => {
    let customCSS = (state.siteConfig.thirdParty ? '/css/aains_theme.css' : '/css/newins_theme.css')
    customCSS = customCSS.concat('?v=' + state.assetVersion)
    return customCSS
  },
  domainID: state => {
    return (location.hostname in state.domainMap) ? state.domainMap[location.hostname] : 0
  }
}

const mutations = {
  set_siteConfig_info (state, siteConfig) {
    state.siteConfig = siteConfig
  },
  set_doesSiteConfigInfoExist_to_true (state) {
    state.doesSiteConfigInfoExist = true
  }
}

const actions = {
  requestSiteConfig ({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.get('/api/pub/site-info', { params: { hostName: location.hostname } })
        .then(resp => {
          let siteConfig = resp.data.data
          commit('set_siteConfig_info', siteConfig)
          commit('set_doesSiteConfigInfoExist_to_true')

          resolve(siteConfig.GATrackingID)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  refreshSiteConfig ({ commit }, siteConfig) {
    commit('set_siteConfig_info', siteConfig)
    commit('set_doesSiteConfigInfoExist_to_true')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
