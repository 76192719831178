import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Axios from 'axios';
var state = {
  profileAPIParams: {
    urlString: '/api/account/policy',
    jsonDataString: 'policyInfo',
    viewType: 'policy'
  },
  messagingAuthorizationKey: '',
  showEnterEmailAddress: true,
  showGetNewCode: false,
  showCodeIsSent: false,
  showResendCode: false,
  showVerifyInput: false,
  showWrongCode: false,
  thanksMessage: false,
  newEmail: ''
};
var getters = {
  profileAPIParams: function profileAPIParams(state) {
    return state.profileAPIParams;
  },
  messagingAuthorizationKey: function messagingAuthorizationKey(state) {
    return state.messagingAuthorizationKey;
  },
  showEnterEmailAddress: function showEnterEmailAddress(state) {
    return state.showEnterEmailAddress;
  },
  showGetNewCode: function showGetNewCode(state) {
    return state.showGetNewCode;
  },
  showCodeIsSent: function showCodeIsSent(state) {
    return state.showCodeIsSent;
  },
  showResendCode: function showResendCode(state) {
    return state.showResendCode;
  },
  showVerifyInput: function showVerifyInput(state) {
    return state.showVerifyInput;
  },
  showWrongCode: function showWrongCode(state) {
    return state.showWrongCode;
  },
  thanksMessage: function thanksMessage(state) {
    return state.thanksMessage;
  },
  newEmail: function newEmail(state) {
    return state.newEmail;
  }
};
var mutations = {
  clear_profile_module_state: function clear_profile_module_state(state) {
    state.messagingAuthorizationKey = '';
    state.showEnterEmailAddress = true;
    state.showGetNewCode = false;
    state.showCodeIsSent = false;
    state.showResendCode = false;
    state.showVerifyInput = false;
    state.showWrongCode = false;
    state.thanksMessage = false;
  },
  set_messaging_authorization_key: function set_messaging_authorization_key(state, value) {
    state.messagingAuthorizationKey = value;
    state.showEnterEmailAddress = false;
    state.showGetNewCode = true;
    state.showResendCode = false;
    state.showCodeIsSent = false;
    state.showVerifyInput = true;
    state.thanksMessage = false;
  },
  set_newEmail: function set_newEmail(state, value) {
    state.newEmail = value;
  },
  set_resent_verification_code: function set_resent_verification_code(state, value) {
    state.messagingAuthorizationKey = value;
    state.showEnterEmailAddress = false;
    state.showCodeIsSent = true;
    state.showGetNewCode = false;
    state.showResendCode = false;
    state.showVerifyInput = true;
    state.thanksMessage = false;
    state.showWrongCode = false;
  },
  set_thanks_message: function set_thanks_message(state) {
    state.thanksMessage = true;
    state.showEnterEmailAddress = false;
    state.showCodeIsSent = false;
    state.showGetNewCode = false;
    state.showResendCode = false;
    state.showVerifyInput = false;
    state.showWrongCode = false;
  },
  set_showCodeIsSent: function set_showCodeIsSent(state) {
    state.showCodeIsSent = true;
    state.showGetNewCode = false;
  },
  update_verification_info: function update_verification_info(state, attemptAgain) {
    state.showGetNewCode = false;
    state.showEnterVerificationCode = false;
    state.showResendCode = !attemptAgain;
    state.showVerifyInput = attemptAgain;
    state.showWrongCode = attemptAgain;
    state.thanksMessage = false;
  }
};
var actions = {
  deleteNonProfileInfo: function deleteNonProfileInfo(_ref) {
    var commit = _ref.commit;
    commit('billingAndPayments/clear_billingAndPayments_module_state', null, {
      root: true
    });
    commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false', null, {
      root: true
    });
    commit('communication/clear_communication_module_state', null, {
      root: true
    });
    commit('clear_profile_module_state');
    commit('documents/clear_documents_module_state', null, {
      root: true
    });
    commit('documents/set_doesDocumentsInfoExist_to_false', null, {
      root: true
    });
  },
  updatePassword: function updatePassword(_ref2, passwordsAndLocale) {
    var commit = _ref2.commit;
    var params = Object.keys(passwordsAndLocale).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(passwordsAndLocale[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/user/update-password', params).then(function (resp) {
        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  sendResetEmail: function sendResetEmail(_ref3, sendResetEmailParam) {
    var commit = _ref3.commit;
    var params = Object.keys(sendResetEmailParam).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(sendResetEmailParam[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/user/email-reset-send', params).then(function (resp) {
        if (sendResetEmailParam.send) {
          commit('set_messaging_authorization_key', resp.data.data.messagingAuthorizationKey);
        } else {
          commit('set_resent_verification_code', resp.data.data.messagingAuthorizationKey);
        }

        commit('set_newEmail', resp.data.data.newEmail);
        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  confirmEmailReset: function confirmEmailReset(_ref4, emailResetData) {
    var commit = _ref4.commit;
    var params = Object.keys(emailResetData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(emailResetData[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/user/email-reset-confirm', params).then(function (resp) {
        if (resp.data.data.codeMatched) {
          commit('set_thanks_message');
        } else {
          commit('update_verification_info', resp.data.data.attemptAgain);
        }

        resolve(resp.data.data.codeMatched);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  clearState: function clearState(_ref5) {
    var commit = _ref5.commit;
    commit('clear_profile_module_state');
  },
  showCodeSent: function showCodeSent(_ref6) {
    var commit = _ref6.commit;
    commit('set_showCodeIsSent');
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};