// Common validations
export var policyNumberFormat = function policyNumberFormat(policy) {
  return /^((02|12|13|27|42)(AU|au|Au|aU|RA|ra|Ra|rA)\d{9})$/.test(policy);
};
export var dateOfBirthFormat = function dateOfBirthFormat(dateOfBirth) {
  return /^([0][1-9]|[1][0-2])\/([0][1-9]|[1][0-9]|[2][0-9]|[3][0-1])\/([1][9][0-9][0-9]|[2][0][0-2][0-9])$/.test(dateOfBirth);
};
export var zipRegex = function zipRegex(zip) {
  return /^[0-9]{5}$/.test(zip);
};
export var phoneRegex = function phoneRegex(phone) {
  return phone === '' ? true : /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phone);
};
export var CVVRegex = function CVVRegex(CVV) {
  return /^[0-9]{3}$/.test(CVV);
};
export var CCExpDateRegex = function CCExpDateRegex(date) {
  return /^([0][0-9]|[1][0-2])[2-3][0-9]$/.test(date);
};
export var verificationCodeRegex = function verificationCodeRegex(verificationCode) {
  return /^[0-9]{6}$/.test(verificationCode);
}; // Custom validations

export var strongPassword = function strongPassword(password) {
  return /^((?=.*[A-Za-z])(?=.*[0-9])|(?=.*[A-Za-z])(?=.*[$%#^&@])|(?=.*[0-9])(?=.*[$%#^&@])).{8,50}$/.test(password);
};
export var nonDefaultOption = function nonDefaultOption(vehicleMake) {
  return !!vehicleMake;
};