//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { strongPassword } from '@/common/validations';
import { required, sameAs } from 'vuelidate/lib/validators';
export default {
  name: 'PasswordResetConfirmationPage',
  data: function data() {
    return {
      errors: [],
      loading: false,
      alertError: false,
      showPasswordResetForm: false,
      passwordNewState: true,
      passwordNewConfirmState: true,
      passwordNew: '',
      passwordNewConfirm: '',
      accountKey: this.$route.query.accountKey,
      passwordResetKey: this.$route.query.passwordResetKey
    };
  },
  computed: {
    locale: function locale() {
      return this.$i18n.locale + '_US';
    }
  },
  validations: {
    passwordNew: {
      required: required,
      strongPassword: strongPassword
    },
    passwordNewConfirm: {
      required: required,
      sameAsPasswordNew: sameAs('passwordNew')
    }
  },
  created: function created() {
    this.loading = true;
    this.callAPIPasswordResetValidate();
  },
  methods: {
    callAPIPasswordResetValidate: function callAPIPasswordResetValidate() {
      var _this = this;

      var accountKey = this.accountKey;
      var passwordResetKey = this.passwordResetKey;
      var currentLocale = this.locale;

      if (accountKey && passwordResetKey) {
        this.$store.dispatch('authentication/passwordResetValidate', {
          accountKey: accountKey,
          passwordResetKey: passwordResetKey,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this.loading = false;
          _this.showPasswordResetForm = true;
        }).catch(function (err) {
          _this.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else {
        this.loading = false;
        this.$router.push({
          name: 'login',
          params: {
            externalError: 'Reset.Errors.InvalidLink'
          }
        });
      }
    },
    passwordResetConfirm: function passwordResetConfirm() {
      var _this2 = this;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.errors = [];
        if (!this.$v.passwordNew.required) this.errors.push('Shared.Element.Errors.PasswordConfirmation');
        if (!this.$v.passwordNew.strongPassword) this.errors.push('Shared.Element.Errors.PasswordValid');
        if (!this.$v.passwordNewConfirm.required || !this.$v.passwordNewConfirm.sameAsPasswordNew) this.errors.push('Profile.ConfirmNewPassword');
        this.alertError = true;
      } else {
        this.loading = true;
        var accountKey = this.accountKey;
        var passwordResetKey = this.passwordResetKey;
        var password = this.passwordNew;
        var passwordConfirmation = this.passwordNewConfirm;
        var currentLocale = this.locale;
        this.$store.dispatch('authentication/passwordResetConfirm', {
          accountKey: accountKey,
          passwordResetKey: passwordResetKey,
          password: password,
          passwordConfirmation: passwordConfirmation,
          currentLocale: currentLocale
        }).then(function (resp) {
          _this2.$router.push({
            name: 'login',
            params: {
              externalSuccess: 'Reset.PasswordResetComplete'
            }
          });
        }).catch(function (err) {
          if (err.response.status === 403) {
            _this2.$router.push({
              name: 'login',
              params: {
                externalError: err.errorArray[0]
              }
            });
          } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this2.errors = err.errorArray;
            _this2.loading = false;
            _this2.alertError = true;
          }
        });
      }
    },
    dismissAlert: function dismissAlert() {
      this.$v.reset();
      this.alertError = false;
    }
  }
};