//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { strongPassword, policyNumberFormat, dateOfBirthFormat, nonDefaultOption } from '@/common/validations';
import { required, email, sameAs, minLength, maxLength } from 'vuelidate/lib/validators';
import TermsAndConditions from '@/components/shared/TermsAndConditions.vue';
export default {
  name: 'RegistrationPage',
  components: {
    TermsAndConditions: TermsAndConditions
  },
  created: function created() {
    this.getVehicleMakes();
  },
  data: function data() {
    return {
      errors: [],
      loading: false,
      showForm: true,
      loadingVehicleMakes: false,
      alertError: false,
      alertSuccess: false,
      alertSuccessMessage: '',
      vehicleMakes: [],
      formPasswordState: true,
      formPasswordConfirmationState: true,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        policyNumber: '',
        insuredDOB: '',
        vehicleMake: '',
        currentLocale: '',
        EDeliverySelected: true
      },
      termsAndConditionsAccepted: 'notAccepted',
      termsAndConditionsType: 'EDelivery',
      termsAndConditionsMethod: 'getEDeliveryTermsAndConditions',
      loadingTerms: false
    };
  },
  computed: {
    examplePolicy: function examplePolicy() {
      return this.$store.getters['siteConfig/examplePolicyPrefix'] + '123456789';
    },
    locale: function locale() {
      return this.$i18n.locale + '_US';
    },
    EDeliveryTermsValid: function EDeliveryTermsValid() {
      return this.form.EDeliverySelected === false || this.termsAndConditionsAccepted === 'accepted';
    }
  },
  validations: {
    form: {
      firstName: {
        required: required
      },
      lastName: {
        required: required
      },
      email: {
        required: required,
        email: email
      },
      emailConfirmation: {
        required: required,
        sameAsEmail: sameAs('email')
      },
      password: {
        required: required,
        minLength: minLength(8),
        maxLength: maxLength(50),
        strongPassword: strongPassword
      },
      passwordConfirmation: {
        required: required,
        sameAsPassword: sameAs('password')
      },
      policyNumber: {
        required: required,
        minLength: minLength(13),
        maxLength: maxLength(13),
        policyNumberFormat: policyNumberFormat
      },
      insuredDOB: {
        required: required,
        dateOfBirthFormat: dateOfBirthFormat
      },
      vehicleMake: {
        nonDefaultOption: nonDefaultOption
      }
    },
    EDeliveryTermsValid: {
      checked: function checked(value) {
        return value === true;
      }
    }
  },
  methods: {
    register: function register() {
      var _this = this;

      this.$v.$touch();

      if (this.$v.$invalid) {
        this.errors = [];
        this.errors.push('Shared.Element.Errors.CorrectErrors');
        this.alertError = true;
      } else {
        this.loading = true;
        this.form.currentLocale = this.locale;
        this.form.policyNumber = this.form.policyNumber.toUpperCase();
        this.form.termsAndConditionsAccepted = this.termsAndConditionsAccepted === 'accepted';
        this.$store.dispatch('authentication/register', this.form).then(function () {
          _this.loading = false;
          _this.alertError = false;
          _this.errors = [];
          _this.alertSuccessMessage = 'Register.DirectionsComplete';
          _this.alertSuccess = true;
          _this.showForm = false;
        }).catch(function (err) {
          _this.handleError(err);

          _this.loading = false;
        });
      }
    },
    handleError: function handleError(err) {
      var _this2 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this2.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
        this.errors = err.errorArray;
        this.alertError = true;
      }
    },
    getVehicleMakes: function getVehicleMakes() {
      var _this3 = this;

      this.loadingVehicleMakes = true;
      this.$store.dispatch('authentication/getVehicleMakes').then(function (resp) {
        _this3.vehicleMakes = resp;
        _this3.loadingVehicleMakes = false;
      }).catch(function (err) {
        _this3.vehicleMakes = ['ACURA', 'ALFA-ROMEO', 'AMERICAN GENERAL', 'AMERICAN MOTORS', 'ASTON MARTIN', 'AUDI', 'AUSTIN', 'AVANTI', 'BENTLEY', 'BERTONE', 'BMW', 'BUICK', 'CADILLAC', 'CHECKER', 'CHEVROLET', 'CHRYSLER', 'DAEWOO', 'DAIHATSU', 'DATSON', 'DODGE', 'EAGLE', 'FERRARI', 'FIAT', 'FISKER', 'FORD', 'GEO', 'GMC', 'HONDA', 'HUMMER', 'HYUNDAI', 'INFINITI', 'ISUZU', 'JAGUAR', 'JEEP', 'KARMA', 'KIA', 'LAMBORGHINI', 'LANCIA', 'LAND ROVER', 'LEXUS', 'LINCOLN', 'LOTUS', 'MASERATI', 'MAYBACH', 'MAZDA', 'MCLAREN', 'MERCEDES-BENZ', 'MERCURY', 'MERKUR', 'MINI', 'MITSUBISHI', 'NISSAN', 'OLDSMOBILE', 'PEUGEOT', 'PINIFARINA', 'PLYMOUTH', 'PONTIAC', 'PORSCHE', 'RAM', 'RENAULT', 'ROLLS ROYCE', 'ROVER', 'SAAB', 'SATURN', 'SCION', 'SMART', 'STERLING', 'SUBARU', 'SUZUKI', 'TESLA', 'TOYOTA', 'TRIUMPH', 'TVR', 'VOLKSWAGEN', 'VOLVO', 'YUGO'];
        _this3.loadingVehicleMakes = false;
        _this3.errors = err.errorArray;
        _this3.alertError = true;
      });
    },
    updateTermsCheckbox: function updateTermsCheckbox() {
      this.termsAndConditionsAccepted = 'notAccepted';
    }
  }
};