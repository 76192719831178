import _objectSpread from "E:/Inetpub/wwwroot/onlinecustomerportal-vuejs/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AccountBase from './AccountBase.vue';
import TermsAndConditions from '@/components/shared/TermsAndConditions.vue';
import { mapGetters } from 'vuex';
import { verificationCodeRegex } from '@/common/validations';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'settingsView',
  extends: AccountBase,
  components: {
    TermsAndConditions: TermsAndConditions
  },
  data: function data() {
    return {
      errors: [],
      loading: false,
      alertError: false,
      alertSuccess: false,
      alertSuccessMessage: '',
      cancelingEnrollment: true,
      cancelingSMSEnrollment: true,
      canceledEDelivery: false,
      changedSMSPhoneNumber: '',
      EDeliveryCodeVerified: false,
      EDeliverySelected: false,
      loadingCancel: false,
      loadingSMSCancel: false,
      loadingSMSUpdate: false,
      selectedSMSPhoneNumber: '',
      termsAndConditionsType: 'EDelivery',
      termsAndConditionsMethod: 'getEDeliveryTermsAndConditions',
      textNotifications: ''
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapGetters('account', ['selectedPolicyNumber'])), mapGetters('communication', ['messagingAuthorizationKey', 'pendingSMSPhoneNumber', 'pendingSMSVerification', 'showCodeHasBeenSent', 'showGetNewCode', 'showResendCode', 'showVerifyInput', 'showWrongCode', 'thanksMessage'])), mapGetters('policy', ['availablePhoneNumbers', 'EDeliveryEnrollable', 'EDeliveryEnrolled', 'EDeliverySMSPhoneNumber', 'policyInfo', 'policyInfoExists', 'mailingAddressLine1', 'mailingAddressLine2'])), {}, {
    EDeliveryVerificationCode: {
      get: function get() {
        return this.$store.getters['communication/EDeliveryVerificationCode'];
      },
      set: function set(newValue) {
        this.$store.dispatch('communication/updateEDeliveryVerificationCode', newValue);
      }
    },
    EDeliveryEnrollableOrPending: function EDeliveryEnrollableOrPending() {
      return this.EDeliveryEnrollable || this.EDeliveryEnrolled === 'Pending confirmation';
    },
    EDeliveryTermsValid: function EDeliveryTermsValid() {
      return this.EDeliverySelected === false || this.termsAndConditionsAccepted === 'accepted';
    },
    email: function email() {
      return this.$store.getters['account/email'];
    },
    manageEnrollment: function manageEnrollment() {
      return this.EDeliveryEnrolled === 'Enrolled' && !this.thanksMessage;
    },
    selectedPolicyNumber: function selectedPolicyNumber() {
      return this.$store.getters['account/selectedPolicyNumber'];
    },
    termsAndConditionsAccepted: {
      get: function get() {
        return this.$store.getters['communication/termsAndConditionsAccepted'];
      },
      set: function set(newValue) {
        this.$store.dispatch('communication/updateTermsAndConditionsAccepted', newValue);
      }
    }
  }),
  created: function created() {
    this.getPageData();
    this.$store.dispatch('account/setSelectedTabName', 'Communication.Header');
  },
  validations: {
    changedSMSPhoneNumber: {
      required: required
    },
    EDeliveryTermsValid: {
      checked: function checked(value) {
        return value === true;
      }
    },
    EDeliveryVerificationCode: {
      required: required,
      verificationCodeRegex: verificationCodeRegex
    },
    selectedSMSPhoneNumber: {
      required: required
    },
    textNotifications: {
      required: required
    }
  },
  methods: {
    getPageData: function getPageData() {
      var _this = this;

      this.loading = true;

      if (!this.policyInfoExists) {
        // If already visited this tab and store data exists.
        if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
          this.callAPI();
        } else {
          // There was a page refresh. Need to populate siteConfig, selectedPolicyNumber, and policyArray in store.
          this.$store.dispatch('account/refreshSessionInfo').then(function (response) {
            _this.callAPI();
          }).catch(function (err) {
            return _this.handleError(err);
          });
        }
      } else {
        this.loading = false;
      }
    },
    callAPI: function callAPI() {
      var _this2 = this;

      var policyPromise = this.$store.dispatch('account/requestData', this.$store.getters['policy/policyAPIParams']);
      var producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams']);
      Promise.all([policyPromise, producerPromise]).then(function () {
        _this2.loading = false;
      }).catch(function (err) {
        return _this2.handleError(err);
      });
    },
    handleError: function handleError(err) {
      var _this3 = this;

      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout').then(function () {
          return _this3.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status;
        this.error = err.errorArray[0];
        this.loading = false;
      }
    },
    dismissAlert: function dismissAlert() {
      this.$v.$reset();
      this.alertError = false;
    },
    dismissThanks: function dismissThanks() {
      this.updateThanksMessage(false);
    },
    cancelEDelivery: function cancelEDelivery() {
      var _this4 = this;

      this.EDeliverySelected = false;
      this.loadingCancel = true;
      var communicationParams = {
        EDeliverySelected: false,
        email: this.email,
        isEmailConfirmed: true,
        policyNumber: this.selectedPolicyNumber,
        termsAndConditionsAccepted: false
      };
      this.$store.dispatch('communication/updateEDelivery', communicationParams).then(function (resp) {
        _this4.loadingCancel = false;
        _this4.cancelingEnrollment = false;
        _this4.canceledEDelivery = true;
      }).catch(function (err) {
        if (err.response.status === 403) {
          _this4.$store.dispatch('authentication/logout').then(function () {
            return _this4.$router.push({
              name: 'login',
              params: {
                externalError: err.errorArray[0]
              }
            });
          });
        } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
          _this4.errors = err.errorArray;
          _this4.alertError = true;
          _this4.loadingCancel = false;
        }
      });
    },
    enrollEDelivery: function enrollEDelivery() {
      var _this5 = this;

      var validated = false;
      this.errors = [];
      this.$v.$reset();
      /* This only resets the modal, it doesn't cancel enrollment. This way if the user enrolls/unenrolls multiple times the modal status is correct */

      this.cancelingEnrollment = true;
      this.$v.EDeliveryTermsValid.$touch();
      this.$v.textNotifications.$touch();

      if (this.$v.EDeliveryTermsValid.$invalid || this.$v.textNotifications.$invalid) {
        this.errors.push('Shared.Element.Errors.CorrectErrors');
        this.alertError = true;
      } else {
        validated = true;

        if (this.textNotifications) {
          this.$v.selectedSMSPhoneNumber.$touch();

          if (this.$v.selectedSMSPhoneNumber.$invalid) {
            validated = false;
            this.errors.push('Shared.Element.Errors.CorrectErrors');
            this.alertError = true;
          }
        }
      }

      if (validated) {
        this.loading = true;
        var communicationParams = {
          EDeliverySelected: this.EDeliverySelected,
          email: this.email,
          isEmailConfirmed: true,
          policyNumber: this.selectedPolicyNumber,
          SMSPhoneNumber: this.selectedSMSPhoneNumber,
          termsAndConditionsAccepted: this.termsAndConditionsAccepted === 'accepted'
        };
        this.$store.dispatch('communication/updateEDelivery', communicationParams).then(function (resp) {
          _this5.loading = false;

          _this5.updateThanksMessage(true);

          _this5.updateEnrollmentStatus('Enrolled');
        }).catch(function (err) {
          if (err.response.status === 403) {
            _this5.$store.dispatch('authentication/logout').then(function () {
              return _this5.$router.push({
                name: 'login',
                params: {
                  externalError: err.errorArray[0]
                }
              });
            });
          } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this5.errors = err.errorArray;
            _this5.alertError = true;
            _this5.loading = false;
          }
        });
      }
    },
    processVerificationCode: function processVerificationCode() {
      var _this6 = this;

      this.errors = [];
      this.$v.$reset();
      this.EDeliveryCodeVerified = false;
      this.$v.EDeliveryVerificationCode.$touch();

      if (this.$v.EDeliveryVerificationCode.$invalid) {
        this.errors.push('Shared.Element.Errors.CorrectErrors');
        this.alertError = true;
      } else {
        this.loading = true;
        var verificationParams = {
          policyNumber: this.selectedPolicyNumber,
          EDeliveryVerificationCode: this.EDeliveryVerificationCode,
          messagingAuthorizationKey: this.messagingAuthorizationKey,
          SMSPhoneNumber: this.pendingSMSPhoneNumber
        };
        this.$store.dispatch('communication/processVerificationCode', verificationParams).then(function (resp) {
          _this6.loading = false;

          if (resp === true) {
            _this6.EDeliveryCodeVerified = true;
            _this6.selectedSMSPhoneNumber = '';
          }
        }).catch(function (err) {
          if (err.response.status === 403) {
            _this6.$store.dispatch('authentication/logout').then(function () {
              return _this6.$router.push({
                name: 'login',
                params: {
                  externalError: err.errorArray[0]
                }
              });
            });
          } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this6.errors = err.errorArray;
            _this6.alertError = true;
            _this6.loading = false;
          }
        });
      }
    },
    sendVerificationCode: function sendVerificationCode() {
      this.$v.$reset();
      var verificationParams = {
        policyNumber: this.selectedPolicyNumber,
        SMSPhoneNumber: this.pendingSMSPhoneNumber
      };
      this.$store.dispatch('communication/sendVerificationCode', verificationParams);
    },
    startOver: function startOver() {
      this.$v.$reset();
      this.$store.dispatch('communication/startOver');
    },
    unenrollEDeliverySMS: function unenrollEDeliverySMS() {
      var _this7 = this;

      this.$v.$reset();
      this.loadingSMSCancel = true;
      var unenrollParams = {
        policyNumber: this.selectedPolicyNumber,
        SMSPhoneNumber: this.EDeliverySMSPhoneNumber
      };
      this.$store.dispatch('communication/unenrollEDeliverySMS', unenrollParams).then(function (resp) {
        _this7.loadingSMSCancel = false;
        _this7.EDeliveryCodeVerified = false;
        _this7.changedSMSPhoneNumber = '';
        /* This only resets the modal, it doesn't cancel SMS enrollment. This way if the user enrolls/unenrolls multiple times the modal status is correct */

        _this7.cancelingSMSEnrollment = false;
      }).catch(function (err) {
        if (err.response.status === 403) {
          _this7.$store.dispatch('authentication/logout').then(function () {
            return _this7.$router.push({
              name: 'login',
              params: {
                externalError: err.errorArray[0]
              }
            });
          });
        } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
          _this7.errors = err.errorArray;
          _this7.alertError = true;
          _this7.loadingSMSCancel = false;
        }
      });
    },
    updateEDeliverySMS: function updateEDeliverySMS() {
      var _this8 = this;

      this.errors = [];
      this.$v.$reset();
      /* This only resets the modal, it doesn't cancel SMS enrollment. This way if the user enrolls/unenrolls multiple times the modal status is correct */

      this.cancelingSMSEnrollment = true;
      this.$v.changedSMSPhoneNumber.$touch();

      if (!this.$v.changedSMSPhoneNumber.$invalid) {
        this.loadingSMSUpdate = true;
        var communicationsParams = {
          policyNumber: this.selectedPolicyNumber,
          SMSPhoneNumber: this.changedSMSPhoneNumber
        };
        this.$store.dispatch('communication/enrollEDeliverySMS', communicationsParams).then(function (resp) {
          _this8.loading = false;
          _this8.selectedSMSPhoneNumber = communicationsParams.SMSPhoneNumber;
          _this8.EDeliveryCodeVerified = false;
          _this8.loadingSMSUpdate = false;
          _this8.changedSMSPhoneNumber = '';
        }).catch(function (err) {
          if (err.response.status === 403) {
            _this8.$store.dispatch('authentication/logout').then(function () {
              return _this8.$router.push({
                name: 'login',
                params: {
                  externalError: err.errorArray[0]
                }
              });
            });
          } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
            _this8.errors = err.errorArray;
            _this8.alertError = true;
            _this8.loadingSMSUpdate = false;
          }
        });
      }
    },
    updateEDeliveryStatus: function updateEDeliveryStatus() {
      if (this.canceledEDelivery) {
        this.updateEnrollmentStatus('UnEnrolled');
        this.canceledEDelivery = false;
      }
    },
    updateEnrollmentStatus: function updateEnrollmentStatus(value) {
      this.$store.dispatch('policy/setEDeliveryEnrolled', value);
    },
    updateTermsCheckbox: function updateTermsCheckbox() {
      this.termsAndConditionsAccepted = 'notAccepted';
    },
    updateThanksMessage: function updateThanksMessage(value) {
      this.$store.dispatch('communication/updateThanksMessage', value);
    }
  },
  watch: {
    selectedPolicyNumber: function selectedPolicyNumber() {
      this.cancelingEnrollment = true;
      this.cancelingSMSEnrollment = true;
      this.EDeliveryCodeVerified = false;
      this.EDeliverySelected = false;
      this.textNotifications = '';
      this.errors = [];
      this.$v.$reset();
    },
    EDeliveryVerificationCode: function EDeliveryVerificationCode() {
      this.$v.$reset();
    }
  }
};