import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ESignBusinessLandingPage',
  data: function data() {
    return {
      // Data used to control template flow
      loading: false,
      error: null,
      showSuccessMessage: false,
      // Values received from ESign through URL query
      reason: this.$route.query.reason || this.$route.query.Reason || '',
      appID: this.$route.query.appID || this.$route.query.AppID || '',
      isWebPayment: this.$route.query.isWebPayment || this.$route.query.IsWebPayment || ''
    };
  },
  created: function created() {
    this.callUpdateEnvelopeStatus();
  },
  methods: {
    callUpdateEnvelopeStatus: function callUpdateEnvelopeStatus() {
      var _this = this;

      this.loading = true;
      var appID = this.appID;
      var reason = this.reason;
      var isWebPayment = this.isWebPayment;

      if (appID <= 0 || isNaN(appID)) {
        this.error = 'ESign.error.DataInvalid'; // Err: AppID from ESign is not a number.

        return;
      } else {
        appID = parseInt(appID, 10);
      }

      if (reason.length > 0) this.checkReason();
      this.$store.dispatch('esign/updateEnvelopeStatus', {
        appID: appID
      }).then(function (resp) {
        _this.showSuccessMessage = true;
        if (isWebPayment) setTimeout(function () {
          window.close();
        }, 5000);
      }).catch(function (err) {
        if (err.errorArray) {
          _this.error = err.errorArray[0];
        } else {
          _this.error = 'Shared.Element.Errors.500'; // Unknown Axios request issue
        }
      }).finally(function () {
        _this.loading = false;
      });
    },
    checkReason: function checkReason() {
      var errorMsg = '';

      switch (this.reason) {
        case 'OnDecline':
          errorMsg = 'ESign.reason.OnDecline';
          break;

        case 'OnCancel':
          errorMsg = 'ESign.reason.OnCancel';
          break;

        case 'OnTTLExpired':
          errorMsg = 'ESign.reason.OnTTLExpired';
          break;

        case 'OnSessionTimeout':
          errorMsg = 'ESign.reason.OnSessionTimeout';
          break;

        case 'OnIdCheckFailed':
          errorMsg = 'ESign.reason.OnIdCheckFailed';
          break;

        default:
          errorMsg = 'ESign.reason.Default';
      }

      this.error = errorMsg;
    }
  }
};