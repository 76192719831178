import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.slice.js";
import Axios from 'axios';
import { setUser } from '@/config/raygun';
var state = {
  account: {
    'accountKey': '',
    'policies': [],
    'selectedPolicyNumber': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'selectedTabName': ''
  }
};
var getters = {
  accountInfo: function accountInfo(state) {
    return state.account;
  },
  accountKey: function accountKey(state) {
    return state.account.accountKey;
  },
  policyArray: function policyArray(state) {
    return state.account.policies;
  },
  selectedPolicyNumber: function selectedPolicyNumber(state) {
    return state.account.selectedPolicyNumber;
  },
  doesSelectedPolicyNumberExist: function doesSelectedPolicyNumberExist(state) {
    return !!state.account.selectedPolicyNumber;
  },
  firstName: function firstName(state) {
    return state.account.firstName;
  },
  lastName: function lastName(state) {
    return state.account.lastName;
  },
  email: function email(state) {
    return state.account.email;
  },
  selectedTabName: function selectedTabName(state) {
    return state.account.selectedTabName;
  }
};
var mutations = {
  update_policyNumber_array: function update_policyNumber_array(state, accountPolicies) {
    state.account.policies = accountPolicies;
  },
  update_selectedPolicyNumber: function update_selectedPolicyNumber(state, policyNumber) {
    state.account.selectedPolicyNumber = policyNumber;
  },
  update_userInfo: function update_userInfo(state, userInfo) {
    state.account.accountKey = userInfo.accountKey;
    state.account.firstName = userInfo.firstName;
    state.account.lastName = userInfo.lastName;
    state.account.email = userInfo.email;
  },
  update_selectedTabName: function update_selectedTabName(state, selectedTabName) {
    state.account.selectedTabName = selectedTabName;
  },
  clear_account_module_state: function clear_account_module_state(state) {
    var a = state.account;
    a.accountKey = '';
    a.policies = [];
    a.selectedPolicyNumber = '';
    a.firstName = '';
    a.lastName = '';
    a.email = '';
    a.selectedTabName = '';
  }
};
var actions = {
  setSelectedTabName: function setSelectedTabName(_ref, selectedTabName) {
    var commit = _ref.commit;
    commit('update_selectedTabName', selectedTabName);
  },
  setPolicyNumberArray: function setPolicyNumberArray(_ref2, policyArray) {
    var commit = _ref2.commit;
    commit('update_policyNumber_array', policyArray);
  },
  setUserInfo: function setUserInfo(_ref3, userInfo) {
    var commit = _ref3.commit;
    commit('update_userInfo', userInfo);
  },
  setSelectedPolicyNumber: function setSelectedPolicyNumber(_ref4, selectedPolicyNumber) {
    var commit = _ref4.commit;
    return new Promise(function (resolve, reject) {
      commit('update_selectedPolicyNumber', selectedPolicyNumber);
      resolve();
    });
  },
  requestData: function requestData(_ref5, _ref6) {
    var _this = this;

    var commit = _ref5.commit;
    var urlString = _ref6.urlString,
        jsonDataString = _ref6.jsonDataString,
        viewType = _ref6.viewType;
    return new Promise(function (resolve, reject) {
      Axios.get(urlString, {
        headers: {
          'policyNumber': _this.getters['account/selectedPolicyNumber'],
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      }).then(function (resp) {
        commit(viewType + '/set_' + viewType + '_data', resp.data.data[jsonDataString], {
          root: true
        });
        commit(viewType + '/set_does' + viewType[0].toUpperCase() + viewType.slice(1) + 'InfoExist_to_true', null, {
          root: true
        });
        resolve(resp.status);
      }).catch(function (err) {
        // Error is processed via Axios interceptor
        reject(err);
      });
    });
  },
  refreshSessionInfo: function refreshSessionInfo(_ref7) {
    var commit = _ref7.commit;
    return new Promise(function (resolve, reject) {
      Axios.get('/api/pub/refresh-session').then(function (resp) {
        var data = resp.data.data.userSession;
        var siteConfig = resp.data.data.siteConfig;
        commit('siteConfig/set_siteConfig_info', siteConfig, {
          root: true
        });
        commit('siteConfig/set_doesSiteConfigInfoExist_to_true', '', {
          root: true
        });

        if (data.isAuthenticated === true) {
          var userInfo = {
            accountKey: data.account.accountKey,
            firstName: data.account.firstName,
            lastName: data.account.lastName,
            email: data.account.email
          };
          commit('update_policyNumber_array', data.account.policies);
          commit('update_selectedPolicyNumber', data.account.policyNumber);
          commit('update_userInfo', userInfo);
          setUser(userInfo);
        }

        resolve(resp.status);
      }).catch(function (err) {
        reject(err);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};