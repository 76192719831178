import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import Axios from 'axios';
var cardConnectIFrameSrcDesktop = '?css=%2Eerror%7Bcolor%3Ared%3B%7Dinput%7Bborder-radius%3A%2E25rem%3Bline-height%3A1%2E5%3Bbox-sizing%3Aborder-box%3Bcolor%3A%23495057%3Bborder-width%3A1px%3Bborder-style%3Asolid%3Bborder-color%3A%23ced4da%3Bfont-weight%3A400%3Bfont-size%3A1rem%3Bpadding-top%3A6px%3Bpadding-right%3A12px%3Bpadding-bottom%3A6px%3Bpadding-left%3A12px%3Bheight%3A38px%3Bwidth%3A500px%7D';
var cardConnectIFrameSrcMobile = '?css=%2Eerror%7Bcolor%3Ared%3B%7Dinput%7Bborder-radius%3A%2E25rem%3Bline-height%3A1%2E5%3Bbox-sizing%3Aborder-box%3Bcolor%3A%23495057%3Bborder-width%3A1px%3Bborder-style%3Asolid%3Bborder-color%3A%23ced4da%3Bfont-weight%3A400%3Bfont-size%3A1rem%3Bpadding-top%3A6px%3Bpadding-right%3A12px%3Bpadding-bottom%3A6px%3Bpadding-left%3A12px%3Bheight%3A38px%3Bwidth%3A220px%7D';
var state = {
  doesRPPInfoExist: false,
  // Page refresh switch, pushing user off RPP and back to Billing.
  stateABBRS: [],
  // state abbreviations for RPP form state select
  RPPInfo: {},
  // RP_ExpMonth, RPPPullDate, RP_StatusCode, RP_ExpYear, RP_CardType, hasActiveNonRenewal, RP_CCNumber4
  RPPPrefillData: {},
  // RPP form prefill data
  eTransVendor: '',
  // CardConnect or Stripe
  eTransVendorParam: '',
  // iFrame src for CardConnect, key for Stripe, modal JS for OneInc
  appID: ''
};
var getters = {
  RPPInfoExists: function RPPInfoExists(state) {
    return state.doesRPPInfoExist;
  },
  RPPInfo: function RPPInfo(state) {
    return state.RPPInfo;
  },
  prefillCardholderAddress: function prefillCardholderAddress(state) {
    return state.RPPPrefillData.MailAddr1.trim();
  },
  prefillCardholderAddress2: function prefillCardholderAddress2(state) {
    return state.RPPPrefillData.MailAddr2.trim();
  },
  prefillCardholderPhone: function prefillCardholderPhone(state) {
    return state.RPPPrefillData.Phone;
  },
  prefillCardholderZip: function prefillCardholderZip(state) {
    return state.RPPPrefillData.MailZipShort;
  },
  prefillCardholderState: function prefillCardholderState(state) {
    return state.RPPPrefillData.MailState;
  },
  prefillCardholderCity: function prefillCardholderCity(state) {
    return state.RPPPrefillData.MailCity.trim();
  },
  prefillCardholderEmail: function prefillCardholderEmail(state) {
    return state.RPPPrefillData.Email;
  },
  eTransVendor: function eTransVendor(state) {
    return state.eTransVendor;
  },
  appID: function appID(state) {
    return state.appID;
  },
  RPPPullDate: function RPPPullDate(state) {
    return state.RPPInfo.RPPPullDate;
  },
  RP_StatusCode: function RP_StatusCode(state) {
    return state.RPPInfo.RP_StatusCode;
  },
  RP_hasActiveNonRenewal: function RP_hasActiveNonRenewal(state) {
    return state.RPPInfo.hasActiveNonRenewal;
  },
  stripeETransVendorParam: function stripeETransVendorParam(state) {
    return state.eTransVendorParam;
  },
  oneIncETransVendorParam: function oneIncETransVendorParam(state) {
    return state.eTransVendorParam;
  },
  cardConnectETransVendorParamDesktop: function cardConnectETransVendorParamDesktop(state) {
    return state.eTransVendorParam.split('?')[0].concat(cardConnectIFrameSrcDesktop);
  },
  cardConnectETransVendorParamMobile: function cardConnectETransVendorParamMobile(state) {
    return state.eTransVendorParam.split('?')[0].concat(cardConnectIFrameSrcMobile);
  },
  stateABBRS: function stateABBRS(state) {
    if (!state.stateABBRS) return [];
    var USstates = state.stateABBRS;
    var stateABBRS = [];
    USstates.forEach(function (el) {
      var x = {
        value: el,
        text: el
      };
      stateABBRS.push(x);
    });
    return stateABBRS;
  }
};
var mutations = {
  set_doesRPPInfoExist_to_true: function set_doesRPPInfoExist_to_true(state) {
    state.doesRPPInfoExist = true;
  },
  set_doesRPPInfoExist_to_false: function set_doesRPPInfoExist_to_false(state) {
    state.doesRPPInfoExist = false;
  },
  clear_RPP_module_state: function clear_RPP_module_state(state) {
    var s = state;
    s.ABBRS = [];
    s.RPPInfo = {};
    s.RPPPrefillData = {};
    s.eTransVendor = '';
    s.eTransVendorParam = '';
    s.appID = '';
  },
  set_RPPInfo: function set_RPPInfo(state, data) {
    state.RPPInfo = data;
  },
  set_stateABBRS: function set_stateABBRS(state, data) {
    state.stateABBRS = data;
  },
  set_RPPPrefillData: function set_RPPPrefillData(state, data) {
    state.RPPPrefillData = data;
  },
  set_eTransVendor: function set_eTransVendor(state, vendorName) {
    state.eTransVendor = vendorName;
  },
  set_eTransVendorParam: function set_eTransVendorParam(state, vendorParam) {
    state.eTransVendorParam = vendorParam;
  },
  set_appID: function set_appID(state, appID) {
    state.appID = appID;
  }
};
var actions = {
  getRPPData: function getRPPData(_ref, policyNum) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      Axios.get('/api/account/billing/rppData', {
        headers: {
          'policyNumber': policyNum
        }
      }).then(function (resp) {
        var RPPData = resp.data.data;
        commit('set_RPPInfo', RPPData.RPPInfo);
        commit('set_stateABBRS', RPPData.stateABBRS);
        commit('set_RPPPrefillData', RPPData.RPPModalPrefillData);
        commit('set_eTransVendor', RPPData.eTransVendor);
        commit('set_eTransVendorParam', RPPData.eTransVendorParam);
        commit('set_appID', RPPData.appID);
        commit('set_doesRPPInfoExist_to_true');
        resolve(RPPData.eTransVendor);
      }).catch(function (err) {
        reject(handleAPIValidationErrors(err));
      });
    });
  },
  zeroDollarRPPAuthorization: function zeroDollarRPPAuthorization(_ref2, RPPData) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      Axios.post('/api/account/billing/zeroDollarRPPAuthorization', buildFormData(RPPData)).then(function (resp) {
        var success = resp.data.data.zeroDollarAuthSuccess;
        resolve(success);
      }).catch(function (err) {
        reject(handleAPIValidationErrors(err));
      });
    });
  },
  zeroDollarAuthorizationSetup: function zeroDollarAuthorizationSetup(_ref3, oneIncSetupData) {
    var commit = _ref3.commit;
    // appID, userID, currentLocale, requestingApp (constant in OCP API)
    return new Promise(function (resolve, reject) {
      Axios.post('/api/account/billing/zeroDollarAuthorizationSetup', buildFormData(oneIncSetupData)).then(function (resp) {
        resolve(resp.data.data.authorizedResponse);
      }).catch(function (err) {
        reject(handleAPIValidationErrors(err));
      });
    });
  },
  saveVendorUIResponse: function saveVendorUIResponse(_ref4, vendorResponseData) {
    var commit = _ref4.commit;
    // ETransID, merchantID, vendorResponseData, vendorResponseContext, requestPayload
    return new Promise(function (resolve, reject) {
      Axios.post('/api/account/billing/saveVendorUIResponse', buildFormData(vendorResponseData)).then(function (resp) {
        resolve(resp.data.data.saveResult.success);
      }).catch(function (err) {
        reject(handleAPIValidationErrors(err));
      });
    });
  },
  zeroDollarAuthorizationWrapup: function zeroDollarAuthorizationWrapup(_ref5, wrapupData) {
    var commit = _ref5.commit;
    // ETransID, userID, cardHolderOnPolicyFlag, cardHolderOnPolicyReason, cardHolderPhone, cardHolderEmail, cardHolderEmailValid
    return new Promise(function (resolve, reject) {
      Axios.post('/api/account/billing/zeroDollarAuthorizationWrapup', buildFormData(wrapupData)).then(function (resp) {
        resolve(resp.data.data.authorizedResponse.zeroDollarAuthSuccess);
      }).catch(function (err) {
        reject(handleAPIValidationErrors(err));
      });
    });
  },
  logRPPError: function logRPPError(_ref6, logData) {
    var commit = _ref6.commit;
    // policyNumber, errorStatus, info, errorType, appID, ETransID, accountGroupCode, requestPayload
    Axios.post('/api/account/billing/logRPPError', buildFormData(logData));
  }
};

function buildFormData(params) {
  var data = new FormData();
  Object.keys(params).map(function (key) {
    data.append(key, params[key]);
  });
  return data;
} // OCP API CBValidation errors handled here.


function handleAPIValidationErrors(err) {
  if (err.response.status === 400) {
    var errorArray = [];
    err.response.data.data.errors.forEach(function (element) {
      return errorArray.push(element);
    });
    err.errorArray = errorArray;
  }

  return err;
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};