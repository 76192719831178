import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import Axios from 'axios';
var state = {};
var getters = {};
var mutations = {};
var actions = {
  getEnvelopeQueueStatus: function getEnvelopeQueueStatus(_ref, envelopeKey) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      Axios.get('/api/pub/esign/envelope-queue-status', {
        params: {
          eSignEnvelopeQueueKey: envelopeKey
        }
      }).then(function (resp) {
        var info = resp.data.data.info;
        resolve(info);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  getSigningURL: function getSigningURL(_ref2, _ref3) {
    var commit = _ref2.commit;
    var appID = _ref3.appID,
        phoneNumber = _ref3.phoneNumber,
        eSignEnvelopeQueueKey = _ref3.eSignEnvelopeQueueKey;
    var data = new FormData();
    data.append('appID', appID);
    data.append('phoneNumber', phoneNumber);
    data.append('eSignEnvelopeQueueKey', eSignEnvelopeQueueKey);
    return new Promise(function (resolve, reject) {
      Axios.post('/api/pub/esign/signing-url', data).then(function (resp) {
        var customerSigningURL = resp.data.data.customerSigningURL;
        resolve(customerSigningURL);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  updateEnvelopeStatus: function updateEnvelopeStatus(_ref4, appID) {
    var commit = _ref4.commit;
    var params = Object.keys(appID).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(appID[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/pub/esign/update-envelope-status', params).then(function (resp) {
        resolve(resp.data.data.profile);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};