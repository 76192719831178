import "E:\\Inetpub\\wwwroot\\onlinecustomerportal-vuejs\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "E:\\Inetpub\\wwwroot\\onlinecustomerportal-vuejs\\node_modules\\core-js\\modules\\es.promise.js";
import "E:\\Inetpub\\wwwroot\\onlinecustomerportal-vuejs\\node_modules\\core-js\\modules\\es.object.assign.js";
import "E:\\Inetpub\\wwwroot\\onlinecustomerportal-vuejs\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import Axios from 'axios';
import IdleVue from 'idle-vue';
import vueHeadful from 'vue-headful';
import VueI18n from 'vue-i18n';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import VueAnalytics from 'vue-analytics';
import messages from './lang';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/main.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faAngleUp, faAngleDown, faMapMarkerAlt, faEye, faEyeSlash, faQuestionCircle, faCheckCircle, faTimesCircle, faExclamationCircle, faIdCard, faFileSignature, faBan, faEdit, faEnvelope, faCreditCard, faReceipt, faCalendar, faBell, faInfoCircle, faRecycle, faLock } from '@fortawesome/free-solid-svg-icons';
import { initializeRaygun, sendError, trackEvent } from './config/raygun';
library.add(faUser, faAngleUp, faAngleDown, faMapMarkerAlt, faEye, faEyeSlash, faQuestionCircle, faCheckCircle, faTimesCircle, faExclamationCircle, faIdCard, faFileSignature, faBan, faEdit, faEnvelope, faCreditCard, faReceipt, faCalendar, faBell, faInfoCircle, faRecycle, faLock);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.component('vue-headful', vueHeadful);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(IdleVue, {
  store: store,
  idleTime: 1200000
}); // Raygun Monitoring

initializeRaygun();

Vue.config.errorHandler = function (err, vm, info) {
  if (vm.$store) {
    var storeState = vm.$store.state;
    sendError(err, {
      info: info,
      storeState: storeState
    }, 'store-catch-all');
  } else {
    sendError(err, {
      info: info
    }, 'catch-all');
  }
};

router.afterEach(function (to, from) {
  trackEvent(to.fullPath);
});
export var i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: messages
}); // Axios response error handling interceptor setup

Axios.interceptors.response.use(function (res) {
  // Checking for ColdFusion's serialized JSON prefix security feature on 200 response
  if (typeof res.data === 'string' && res.data.substring(0, 2) === '//') {
    res.data = JSON.parse(res.data.substring(2));
  }

  return res;
}, function (err) {
  var errorArray = [];

  if (err.response) {
    // Checking for ColdFusion's serialized JSON prefix security feature on !200 response
    if (typeof err.response.data === 'string' && err.response.data.substring(0, 2) === '//') {
      err.response.data = JSON.parse(err.response.data.substring(2));
    }

    if (err.response.status === 403) {
      errorArray.push('Shared.Element.Errors.403');
    } else if (err.response.status === 404) {
      errorArray.push('Shared.Element.Errors.404');
    } else if (err.response.status === 500) {
      errorArray.push('Shared.Element.Errors.500');
    }
  } else if (err.request) {
    errorArray.push('Err: Request to API failed'); // Response never made it back from API
  }

  err.errorArray = errorArray;

  if (store) {
    var storeState = store.state;
    sendError(err, {
      errorData: err.response,
      storeState: storeState
    }, 'store-axios');
  } else {
    sendError(err, {
      errorData: err.response
    }, 'axios');
  }

  return Promise.reject(err);
});
var domainID = store.getters['siteConfig/domainID'];

if (domainID) {
  var OtAutoBlockScript = document.createElement('script');
  OtAutoBlockScript.type = 'text/javascript';
  OtAutoBlockScript.src = 'https://cdn.cookielaw.org/consent/' + domainID + '/OtAutoBlock.js';
  var otSDKStubScript = document.createElement('script');
  otSDKStubScript.type = 'text/javascript';
  otSDKStubScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  otSDKStubScript.charset = 'UTF-8';
  otSDKStubScript.setAttribute('data-domain-script', domainID);
  var oneTrustFct = document.createElement('script');
  oneTrustFct.setAttribute('type', 'text/javascript');
  oneTrustFct.innerText = 'function OptanonWrapper() {}';
  document.head.insertBefore(OtAutoBlockScript, document.head.firstChild);
  document.head.insertBefore(otSDKStubScript, OtAutoBlockScript.nextSibling);
  document.head.insertBefore(oneTrustFct, otSDKStubScript.nextSibling);
}

Vue.use(VueAnalytics, {
  id: store.dispatch('siteConfig/requestSiteConfig').then(function (resp) {
    return resp;
  }).catch(function () {
    router.push({
      name: '500'
    });
  }),
  router: router
});
Vue.prototype.$http = Axios;
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');