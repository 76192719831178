const state = {
  doesPolicyInfoExist: false,
  EDeliveryCommunications: [],
  EDeliveryEnrolled: 'Never enrolled',
  EDeliveryEnrollable: true,
  EDeliverySMSPhoneNumber: '',
  mailingAddressLine1: '',
  mailingAddressLine2: '',
  policyInfo: {},
  allowPolicyPayment: false,
  policyAPIParams: {
    urlString: '/api/account/policy',
    jsonDataString: 'policyInfo',
    viewType: 'policy'
  }
}

const getters = {
  availablePhoneNumbers: state => {
    let allNumbers = []
    /* phone: home, phone2: mobile, phone3: alt */
    if (state.policyInfo.phone) allNumbers.push(state.policyInfo.phone)
    if (state.policyInfo.phone2) allNumbers.push(state.policyInfo.phone2)
    if (state.policyInfo.phone3) allNumbers.push(state.policyInfo.phone3)

    if (allNumbers.indexOf(state.EDeliverySMSPhoneNumber) >= 0) allNumbers.splice(allNumbers.indexOf(state.EDeliverySMSPhoneNumber), 1)

    return allNumbers
  },
  EDeliveryEnrolled: state => state.EDeliveryEnrolled,
  EDeliveryEnrollable: state => state.EDeliveryEnrollable,
  EDeliverySMSPhoneNumber: state => state.EDeliverySMSPhoneNumber,
  policyInfo: state => state.policyInfo,
  policyInfoExists: state => state.doesPolicyInfoExist,
  policyAPIParams: state => state.policyAPIParams,
  policyStatus: state => state.policyInfo.policyStatus,
  mailingAddressLine1: state => state.mailingAddressLine1,
  mailingAddressLine2: state => state.mailingAddressLine2,
  garageAddressLine1: state => state.policyInfo.address.garage.address1,
  garageAddressLine2: state => state.policyInfo.address.garage.city + ', ' + state.policyInfo.address.garage.state + ' ' + state.policyInfo.address.garage.zip,
  IDCardExists: state => !!state.policyInfo.IDCard.display,
  IDCardLink: state => (state.policyInfo.IDCard.display ? state.policyInfo.IDCard.locationEncrypted : '#'),
  allowPolicyPayment: state => state.policyInfo.allowPolicyPayment ?? true
}

const mutations = {
  set_policy_data (state, policyData) {
    state.policyInfo = policyData
    state.EDeliveryEnrolled = policyData.EDeliveryInfo.enrollmentStatus
    state.EDeliveryEnrollable = policyData.EDeliveryInfo.enrollable
    state.EDeliverySMSPhoneNumber = policyData.EDeliveryInfo.communications.length ? policyData.EDeliveryInfo.communications[0].phoneNumber : ''
    state.mailingAddressLine1 = policyData.address.mailing.address1
    state.mailingAddressLine2 = policyData.address.mailing.city + ', ' + policyData.address.mailing.state + ' ' + policyData.address.mailing.zip
    state.EDeliveryCommunications = policyData.EDeliveryInfo.communications
  },
  set_EDelivery_enrolled (state, value) {
    state.EDeliveryEnrolled = value
    state.EDeliveryEnrollable = (value !== 'Enrolled')
  },
  set_EDeliverySMSPhoneNumber (state, value) {
    state.EDeliverySMSPhoneNumber = value
  },
  set_doesPolicyInfoExist_to_true (state) {
    state.doesPolicyInfoExist = true
  },
  set_doesPolicyInfoExist_to_false (state) {
    state.doesPolicyInfoExist = false
  },
  clear_policy_module_state (state) {
    state.policyInfo = {}
  }
}

const actions = {
  setEDeliveryEnrolled ({ commit }, value) {
    commit('set_EDelivery_enrolled', value)
  },
  setEDeliverySMSPhoneNumber ({ commit }, value) {
    commit('set_EDeliverySMSPhoneNumber', value)
  },
  setPolicyDataInStore ({ commit }, policyData) {
    commit('set_policy_data', policyData)
  },
  deleteNonPolicyInfo ({ commit }) {
    commit('billingAndPayments/clear_billingAndPayments_module_state', null, { root: true })
    commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false', null, { root: true })
    commit('communication/clear_communication_module_state', null, { root: true })
    commit('documents/clear_documents_module_state', null, { root: true })
    commit('documents/set_doesDocumentsInfoExist_to_false', null, { root: true })
    commit('profile/clear_profile_module_state', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
