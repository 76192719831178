<template>
  <section>
    <div v-if="error" class="text-center">
      <h1>{{status}} {{ $t('Shared.Element.Error') }}</h1>
      <p>{{ $t(error)}}</p>
    </div>

    <div v-else>

      <div v-if="loading" class="loading">
        <div class="overlay">
          <b-spinner variant="primary" label="Spinning" class="uil-ring-css"></b-spinner>
        </div>
      </div>

      <div v-if="policyInfoExists && !loading">
        <!-- Desktop -->
        <div class="row desktop-view">
          <div class="col-md-7">
            <div class="white_canvas min-high-500-desktop">
              <template v-if="IDCardExists">
                <span class="float-right mt-less-10">
                  <b-button :href="IDCardLink" variant="outline-primary"><font-awesome-icon icon="id-card" /> {{ $t('Policy.getIDCard') }}</b-button>
                  <b-button id="GetIDDToolTip" href="#" tabindex="0" variant="light" class="helpToolTip mt-2"><font-awesome-icon icon="question-circle" /></b-button>
                  <b-popover target="GetIDDToolTip" triggers="focus" placement="right">
                    {{ $t('Shared.Element.DocumentIDCardPhone') }}
                  </b-popover>
                </span>
              </template>
              <h1>{{ $t('Policy.Highlights') }}</h1>
              <hr>
              <div class="row">
                <div class="col-md-5">
                  <p><strong>{{ $t('Shared.Element.PolicyStatus') }}: </strong>
                    <b-badge :variant="statusBadgeVariant">{{policyInfo.policyStatus}}</b-badge>
                  </p>
                  <p><strong>{{ $t('Shared.Element.PolicyTerm') }}: </strong>{{policyInfo.policyTerm}} {{ $t('Shared.Element.Months') }}</p>
                </div>
                <div class="col-md-7">
                  <p class="mb-0"><strong>{{ $t('Shared.Element.EffectiveDate') }}: </strong>{{policyInfo.effectiveDate}}</p>
                  <p><strong>{{ $t('Shared.Element.ExpirationDate') }}: </strong>{{policyInfo.expirationDate}}</p>
                </div>
              </div>
              <hr>
              <h1>{{ $t('Shared.Element.PolicyHolder') }}</h1>
              <hr>
              <div class="row">
                <div class="col-md-12 pb-2">
                  <p><strong>{{policyInfo.insuredName}}</strong></p>
                  <p v-if="policyInfo.phone" class="mb-0">{{policyInfo.phone}}</p>
                  <p v-if="policyInfo.phone2" class="mb-0">{{policyInfo.phone2}}</p>
                  <p v-if="policyInfo.phone3" class="mb-0">{{policyInfo.phone3}}</p>
                  <p v-if="policyInfo.email" class="mb-0"> {{policyInfo.email}}</p>
                </div>
                <div class="col-md-6">
                  <p><strong>{{ $t('Shared.Element.InsuredAddress') }}</strong></p>
                  <p class="pb-0">{{mailingAddressLine1}}<br>{{mailingAddressLine2}}</p>
                </div>
                <div class="col-md-6">
                  <p><strong>{{ $t('Shared.Element.VehicleAddress') }}</strong></p>
                  <p class="pb-0">{{garageAddressLine1}}<br>{{garageAddressLine2}}</p>
                </div>
              </div>

            </div>
          </div>
          <div class="col-md-5 desktop-view">
            <div class="white_canvas min-high-500-desktop">
              <store-ref/>
              <hr>
              <h1 class="mt-4 pt-1">{{ $t('Shared.Element.Payments') }}</h1>
              <hr>
              <strong>{{ $t('Billing.NextPaymentDate') }} {{policyInfo.billingInfo.results.dueDate}}</strong>
              <div v-if="policyInfo.billingInfo.hasErrors">
                {{ $t('Billing.Error') }}
              </div>
              <div v-else>
                <h1 class="h_strong">{{policyInfo.billingInfo.results.amountDue}}</h1>
              </div>
              <div class="mt-3 pb-4">
                <b-button v-if="allowPolicyPayment" @click="$router.push({ name: 'billingAndPayments' })" variant="primary" size="lg" class="col-md-6 font-16"> {{ $t('Shared.Element.ManagePayments') }}</b-button>
                <b-button v-else variant="secondary" size="lg" class="col-md-6 font-16" disabled><font-awesome-icon icon="ban" /> {{ $t('Shared.Element.ManagePayments') }}</b-button>
              </div>
            </div>
          </div>
        </div>
        <!-- /Desktop -->
        <!-- Mobile -->
        <div class="row mobile-view">
          <div class="col-md-12">
            <div class="white_canvas ">
              <h1>{{ $t('Policy.Highlights') }}</h1>
              <hr>
              <div class="row">
                <div class="col-md-5">
                  <p><strong>{{ $t('Shared.Element.PolicyStatus') }}: </strong>
                    <b-badge :variant="statusBadgeVariant">{{policyInfo.policyStatus}}</b-badge>
                  </p>
                  <p><strong>{{ $t('Shared.Element.PolicyTerm') }}: </strong>12 {{ $t('Shared.Element.Months') }}</p>
                </div>
                <div class="col-md-7">
                  <p><strong>{{ $t('Shared.Element.EffectiveDate') }}: </strong>{{policyInfo.effectiveDate}}</p>
                  <p><strong>{{ $t('Shared.Element.ExpirationDate') }}: </strong>{{policyInfo.expirationDate}}</p>
                  <template v-if="IDCardExists">
                    <b-button :href="IDCardLink" variant="outline-primary"><font-awesome-icon icon="id-card" /> {{ $t('Policy.getIDCard') }}</b-button>
                    <b-button id="MobileIDToolTip" href="#" tabindex="0" variant="light" class="helpToolTip mt-2"><font-awesome-icon icon="question-circle" /></b-button>
                    <b-popover target="MobileIDToolTip" triggers="focus" placement="top">
                      {{ $t('Shared.Element.DocumentIDCardPhone') }}
                    </b-popover>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="white_canvas ">
              <h1>{{ $t('Shared.Element.Payments') }}</h1>
              <hr>
              <strong>{{ $t('Billing.NextPaymentDate') }} {{policyInfo.billingInfo.results.dueDate}}</strong>
              <h1 class="h_strong">{{policyInfo.billingInfo.results.amountDue}}</h1>
              <div class="mt-3 pb-4">
                <b-button v-if="allowPolicyPayment" @click="$router.push({ name: 'billingAndPayments' })" variant="primary" size="lg" class="col-md-6 font-16"> {{ $t('Shared.Element.ManagePayments') }}</b-button>
                <b-button v-else variant="secondary" size="lg" class="col-md-6 font-16" disabled><font-awesome-icon icon="ban" /> {{ $t('Shared.Element.ManagePayments') }}</b-button>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="white_canvas">
              <h1>{{ $t('Shared.Element.PolicyHolder') }}</h1>
              <hr>
              <div class="row">
                <div class="col-md-4 pb-4">
                  <p><strong>{{policyInfo.insuredName}}</strong></p>
                  <p v-if="policyInfo.phone" class="mb-0">{{policyInfo.phone}}</p>
                  <p v-if="policyInfo.phone2" class="mb-0">{{policyInfo.phone2}}</p>
                  <p v-if="policyInfo.phone3" class="mb-0">{{policyInfo.phone3}}</p>
                  <p v-if="policyInfo.email" class="mb-0"> {{policyInfo.email}}</p>
                </div>
                <div class="col-md-4 pb-4">
                  <p><strong>{{ $t('Shared.Element.InsuredAddress') }}</strong></p>
                  <p>{{mailingAddressLine1}}<br>{{mailingAddressLine2}}</p>
                </div>
                <div class="col-md-4 pb-4">
                  <p><strong>{{ $t('Shared.Element.VehicleAddress') }}</strong></p>
                  <p>{{garageAddressLine1}}<br>{{garageAddressLine2}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Mobile -->
        <div class="row">
          <div class="col-md-5">
            <div class="white_canvas min-high-620-desktop">
              <span class="float-left pr-3"><img src="@/assets/images/car_wheel_icon.svg" alt=""></span>
              <h1 class="primary-blue-text">{{ $t('Shared.Element.Drivers') }}</h1>

                <b-card no-body class="mb-1 mt-3">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block href="#" v-b-toggle.accordion-1 variant="link" class="text-left font-weight-bold dark-blue" >{{ $t('Shared.Element.InsuredDrivers') }}
                      <span class="when-opened"><font-awesome-icon icon="angle-up" class="float-right"/></span>
                      <span class="when-closed"><font-awesome-icon icon="angle-down" class="float-right"/></span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <driver v-for="driver in insuredDrivers" :key="driver.driverNum" :driver="driver"></driver>
                    </b-card-body>
                  </b-collapse>
                </b-card>

                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block href="#" v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold dark-blue" >{{ $t('Shared.Element.ExcludedDrivers') }}
                      <span class="when-opened"><font-awesome-icon icon="angle-up" class="float-right"/></span>
                      <span class="when-closed"><font-awesome-icon icon="angle-down" class="float-right"/></span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <driver v-for="driver in excludedDrivers" :key="driver.driverNum" :driver="driver"></driver>
                      <template v-if="!excludedDrivers.length">{{ $t('Shared.Element.NoExcludedDrivers') }}</template>
                    </b-card-body>
                  </b-collapse>
                </b-card>
            </div>
          </div>
          <div class="col-md-7">
            <div class="white_canvas min-high-620-desktop">
              <span class="float-left pr-3"><img src="@/assets/images/car_icon.svg" alt=""></span>
              <h1 class="primary-blue-text ">{{ $t('Shared.Element.Vehicles') }}</h1>
              <vehicle v-for="vehicle in policyInfo.vehicles" :key="vehicle.vehicleNumber" :vehicleNumber="vehicle.vehicleNumber" :vehicle="vehicle"></vehicle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import StoreRef from '@/components/shared/TheStoreRef.vue'
import Driver from '@/components/account/policy/Driver.vue'
import Vehicle from '@/components/account/policy/Vehicle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'policyView',
  components: {
    storeRef: StoreRef,
    driver: Driver,
    vehicle: Vehicle
  },
  data () {
    return {
      loading: false,
      error: null,
      status: null
    }
  },
  computed: {
    ...mapGetters('policy', [
      'policyInfo',
      'policyInfoExists',
      'mailingAddressLine1',
      'mailingAddressLine2',
      'garageAddressLine1',
      'garageAddressLine2',
      'IDCardExists',
      'IDCardLink',
      'allowPolicyPayment'
    ]),
    ...mapGetters('account', [
      'selectedPolicyNumber'
    ]),
    statusBadgeVariant () {
      const successStatuses = ['In-Force', 'Reinstated Without Lapse', 'Reinstated With Lapse', 'Renewed']
      let policyStatus = this.$store.getters['policy/policyStatus']
      if (policyStatus) return (successStatuses.indexOf(policyStatus) !== -1 ? 'success' : 'danger')
      return ''
    },
    insuredDrivers () {
      return this.policyInfo.drivers.filter(driver => driver.category === 'P')
    },
    excludedDrivers () {
      return this.policyInfo.drivers.filter(driver => driver.category === 'E')
    }
  },
  created () {
    this.getPageData()
    this.$store.dispatch('account/setSelectedTabName', 'Policy.Header')
  },
  methods: {
    getPageData () {
      this.loading = true

      if (!this.policyInfoExists) { // If already visited this tab and store data exists.
        if (this.$store.getters['account/doesSelectedPolicyNumberExist']) {
          this.callAPI()
        } else { // There was a page refresh. Need to populate siteConfig, selectedPolicyNumber, and policyArray in store.
          this.$store.dispatch('account/refreshSessionInfo')
            .then(response => {
              this.callAPI()
            })
            .catch(err => this.handleError(err))
        }
      } else {
        this.loading = false
      }
    },
    callAPI () {
      const policyPromise = this.$store.dispatch('account/requestData', this.$store.getters['policy/policyAPIParams'])
      const producerPromise = this.$store.dispatch('account/requestData', this.$store.getters['producer/producerAPIParams'])

      Promise.all([policyPromise, producerPromise])
        .then(() => {
          this.loading = false
        })
        .catch(err => this.handleError(err))
    },
    handleError (err) {
      if (err.response.status === 403) {
        this.$store.dispatch('authentication/logout')
          .then(() => this.$router.push({ name: 'login', params: { externalError: err.errorArray[0] } }))
      } else if (err.response.status === 404 || err.response.status === 500) {
        this.status = err.response.status
        this.error = err.errorArray[0]
        this.loading = false
      }
    }
  },
  watch: {
    selectedPolicyNumber () {
      this.error = null
    }
  }
}
</script>
