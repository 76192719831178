//
//
//
//
//
//
//
//
//
//
import Header from './components/shared/TheHeader.vue';
import Footer from './components/shared/TheFooter.vue';
export default {
  components: {
    appHeader: Header,
    appFooter: Footer
  },
  computed: {
    siteConfigExists: function siteConfigExists() {
      return this.$store.getters['siteConfig/siteConfigInfoExists'];
    },
    titlePageName: function titlePageName() {
      return this.$t(this.$route.meta.title);
    },
    titleCompanyName: function titleCompanyName() {
      return this.siteConfigExists ? ' - ' + this.$store.getters['siteConfig/companyName'] : '';
    },
    // Building the page title based on which route we are on & the company name.
    title: function title() {
      return this.titlePageName + this.titleCompanyName + (this.titleCompanyName === ' - Kemper Auto' ? ' | ' + this.$t('Shared.Meta.Title.company') : '');
    },
    customCSS: function customCSS() {
      return this.$store.getters['siteConfig/customCSSHref'];
    }
  },
  // Setting OCP language if external value is passed in from AAXIS, email, etc.
  mounted: function mounted() {
    this.$i18n.locale = this.$route.query.language || this.$route.query.lang || 'en';
  },
  watch: {
    siteConfigExists: function siteConfigExists(newBoolean) {
      if (newBoolean) {
        this.setCustomCSS();
      }
    }
  },
  methods: {
    setCustomCSS: function setCustomCSS() {
      var linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.type = 'text/css';
      linkElement.href = this.customCSS;
      document.head.appendChild(linkElement);
    }
  }
};