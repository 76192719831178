const state = {
  doesBillingAndPaymentsInfoExist: false,
  billingInfo: {},
  billingAndPaymentsAPIParams: {
    urlString: '/api/account/billing',
    jsonDataString: 'billingAndRPPInfo',
    viewType: 'billingAndPayments'
  }
}

const getters = {
  billingInfo: state => state.billingInfo,
  billingAndPaymentsInfoExist: state => state.doesBillingAndPaymentsInfoExist,
  billingAndPaymentsAPIParams: state => state.billingAndPaymentsAPIParams,
  driverDOB: state => (state.billingInfo.driver ? state.billingInfo.driver.DOB : ''),
  RPPShowSignUp: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.showSignUp : ''),
  RPPShowEdit: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.showEdit : ''),
  RPPDisableButton: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.disableButton : ''),
  RPPStatus: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.status : ''),
  RPPButtonText: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.buttonText : ''),
  RPPFullCardType: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.fullCardType : ''),
  RPPCCNumber4: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.CCNumber4 : ''),
  RPPExpDate: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.expDate : ''),
  RPPAmountDue: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.amountDue : ''),
  RPPPullDate: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.pullDate : ''),
  RPPToolTipMessage: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.message : ''),
  RPPGoogleAnalyticsLabel: state => (state.billingInfo.RPPDisplay ? state.billingInfo.RPPDisplay.googleEventLabel : '')
}

const mutations = {
  set_billingAndPayments_data (state, billingData) {
    state.billingInfo = billingData
  },
  set_doesBillingAndPaymentsInfoExist_to_true (state) {
    state.doesBillingAndPaymentsInfoExist = true
  },
  set_doesBillingAndPaymentsInfoExist_to_false (state) {
    state.doesBillingAndPaymentsInfoExist = false
  },
  clear_billingAndPayments_module_state (state) {
    state.billingInfo = {}
  }
}

const actions = {
  deleteNonBillingAndPaymentsInfo ({ commit }) {
    commit('communication/clear_communication_module_state', null, { root: true })
    commit('policy/clear_policy_module_state', null, { root: true })
    commit('policy/set_doesPolicyInfoExist_to_false', null, { root: true })
    commit('profile/clear_profile_module_state', null, { root: true })
    commit('documents/clear_documents_module_state', null, { root: true })
    commit('documents/set_doesDocumentsInfoExist_to_false', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
