import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import Axios from 'axios';
var state = {
  defaultTerm: '',
  doesDocumentsInfoExist: false,
  documents: [],
  formsCategory: [],
  IDCardExists: false,
  logClickEvent: false,
  receiptURL: '',
  terms: [],
  documentsAPIParams: {
    urlString: '/api/account/documents',
    jsonDataString: 'documentInfo',
    viewType: 'documents'
  }
};
var getters = {
  documentsInfo: function documentsInfo(state) {
    return state.documents;
  },
  documentsInfoExist: function documentsInfoExist(state) {
    return state.doesDocumentsInfoExist;
  },
  IDCardExists: function IDCardExists(state) {
    return state.IDCardExists;
  },
  defaultTerm: function defaultTerm(state) {
    return state.defaultTerm;
  },
  documentItems: function documentItems(state) {
    var docs = [];
    state.documents.forEach(function (element) {
      var docObj = {
        display: element.display,
        formsCategory: element.formsCategory,
        keyTerm: element.formsCategory + ' ' + element.term,
        link: element.locationEncrypted,
        name: element.name,
        term: element.term,
        termEffectiveDate: element.termEffectiveDate
      };
      docs.push(docObj);
    });
    return docs;
  },
  documentsAPIParams: function documentsAPIParams(state) {
    return state.documentsAPIParams;
  },
  formsCategory: function formsCategory(state) {
    var formsCategories = [];
    state.formsCategory.forEach(function (element) {
      var formsCategoryObject = {
        catName: element.catName,
        sharedName: 'Shared.Element.' + element.catName
      };
      formsCategories.push(formsCategoryObject);
    });
    return formsCategories;
  },
  initialCount: function initialCount(state) {
    var count = 0;
    state.documents.forEach(function (element) {
      if (state.defaultTerm === element.term && element.formsCategory === 'Policy') {
        count++;
      }
    });
    return count;
  },
  logClickEvent: function logClickEvent(state) {
    return state.logClickEvent;
  },
  receiptURL: function receiptURL(state) {
    return state.receiptURL;
  },
  terms: function terms(state) {
    var policyTerms = [];
    state.terms.forEach(function (element) {
      var termObj = {
        effectiveDate: element.effectiveDate,
        expirationDate: element.expirationDate,
        name: element.name,
        sharedName: 'Shared.Element.' + element.name
      };
      policyTerms.push(termObj);
    });
    return policyTerms;
  }
};
var mutations = {
  set_documents_data: function set_documents_data(state, documentsData) {
    state.defaultTerm = documentsData.defaultTerm;
    state.documents = documentsData.documents;
    state.formsCategory = documentsData.formsCategory;
    state.IDCardExists = documentsData.hasIDCard;
    state.logClickEvent = documentsData.logClickEvent;
    state.receiptURL = documentsData.receiptURL;
    state.terms = documentsData.terms;
  },
  set_doesDocumentsInfoExist_to_true: function set_doesDocumentsInfoExist_to_true(state) {
    state.doesDocumentsInfoExist = true;
  },
  set_doesDocumentsInfoExist_to_false: function set_doesDocumentsInfoExist_to_false(state) {
    state.doesDocumentsInfoExist = false;
  },
  set_currentPolicy_documents: function set_currentPolicy_documents(state, policyDocuments) {
    state.currentPolicy = policyDocuments;
  },
  clear_documents_module_state: function clear_documents_module_state(state) {
    state.documents = [];
  }
};
var actions = {
  deleteNonDocumentsInfo: function deleteNonDocumentsInfo(_ref) {
    var commit = _ref.commit;
    commit('policy/clear_policy_module_state', null, {
      root: true
    });
    commit('policy/set_doesPolicyInfoExist_to_false', null, {
      root: true
    });
    commit('billingAndPayments/clear_billingAndPayments_module_state', null, {
      root: true
    });
    commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false', null, {
      root: true
    });
    commit('communication/clear_communication_module_state', null, {
      root: true
    });
    commit('profile/clear_profile_module_state', null, {
      root: true
    });
  },
  logDocumentClickEvent: function logDocumentClickEvent(_ref2, logData) {
    var commit = _ref2.commit;
    Axios.post('/api/account/documents/logDocumentClickEvent', buildFormData(logData));
  }
};

function buildFormData(params) {
  var data = new FormData();
  Object.keys(params).map(function (key) {
    data.append(key, params[key]);
  });
  return data;
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};