import _objectSpread from "E:/Inetpub/wwwroot/onlinecustomerportal-vuejs/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { policyNumberFormat, dateOfBirthFormat } from '@/common/validations';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
export default {
  name: 'PaymentGuestPage',
  data: function data() {
    return {
      errors: [],
      alertError: false,
      txtPolicyNum: '',
      txtDateOfBirth: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('siteConfig', ['companyName'])), {}, {
    paymentURL: function paymentURL() {
      var currentLanguage = this.$i18n.locale;
      var thirdParty = this.$store.getters['siteConfig/thirdParty'];
      return currentLanguage === 'es' && thirdParty ? this.$store.getters['siteConfig/paymentURL'].concat('?lang=2') : this.$store.getters['siteConfig/paymentURL'];
    },
    dynamicTarget: function dynamicTarget() {
      var thirdParty = this.$store.getters['siteConfig/thirdParty'];
      return thirdParty ? '_self' : '_blank';
    },
    examplePolicy: function examplePolicy() {
      return this.$store.getters['siteConfig/examplePolicyPrefix'] + '123456789';
    },
    showDisclaimer: function showDisclaimer() {
      return this.companyName === 'Kemper Auto';
    }
  }),
  validations: {
    txtPolicyNum: {
      required: required,
      policyNumberFormat: policyNumberFormat
    },
    txtDateOfBirth: {
      required: required,
      dateOfBirthFormat: dateOfBirthFormat
    }
  },
  methods: {
    processForm: function processForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.validateForm();
      } else {
        if (this.txtPolicyNum.length === 13 && this.txtDateOfBirth.length === 10) {
          this.$ga.event('OCP', 'click', 'Payment - Public');
          document.getElementById('makePayment').submit();
        } else {
          this.errors.push('Shared.Element.Errors.AccountInvalid');
          this.alertError = true;
        }
      }
    },
    validateForm: function validateForm() {
      this.errors = [];
      if (!this.$v.txtPolicyNum.required || !this.$v.txtPolicyNum.policyNumberFormat) this.errors.push('Shared.Element.Errors.PolicyNumber');
      if (!this.$v.txtDateOfBirth.required) this.errors.push('Shared.Element.Errors.ValidDOB');
      this.alertError = true;
    },
    dismissAlert: function dismissAlert() {
      this.$v.$reset();
      this.alertError = false;
    }
  }
};