import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'TheHeader',
  computed: {
    showDisclaimer: function showDisclaimer() {
      return !this.isLoggedIn && this.isAAINSPortal && this.$t('Shared.Header.Navigation.disclaimer') !== '';
    },
    isLoggedIn: function isLoggedIn() {
      return this.$store.getters['authentication/isLoggedIn'];
    },
    siteConfigExists: function siteConfigExists() {
      return this.$store.getters['siteConfig/siteConfigInfoExists'];
    },
    desktopLogoSrc: function desktopLogoSrc() {
      var logo = this.$store.getters['siteConfig/logo'];
      return logo ? require('@/assets/images/desktop/' + logo) : '';
    },
    mobileLogoSrc: function mobileLogoSrc() {
      var logo = this.$store.getters['siteConfig/logo'];
      return logo ? require('@/assets/images/mobile/' + logo) : '';
    },
    isAAINSPortal: function isAAINSPortal() {
      return this.$store.getters['siteConfig/companyName'].includes('Kemper Auto');
    }
  },
  methods: {
    logout: function logout() {
      var _this = this;

      this.$store.dispatch('authentication/logout').then(function () {
        _this.$router.push({
          name: 'login'
        });
      }).catch(function (err) {
        _this.$router.push({
          name: 'login',
          params: {
            externalError: err.errorArray[0]
          }
        });
      });
    },
    setLanguage: function setLanguage(val) {
      this.$i18n.locale = val;
    }
  }
};