//
//
//
//
//
//
//
//
//
//
export default {
  name: 'TermsAndConditions',
  mounted: function mounted() {
    var _this = this;

    this.$root.$on('bv::modal::show', function (bvEvent, modalId) {
      if (modalId === 'modal-termsAndConditions') {
        _this.getTermsAndConditions();
      }
    });
  },
  data: function data() {
    return {
      loadingTerms: false
    };
  },
  props: {
    termsType: String,
    termsMethod: String
  },
  computed: {
    termsAndConditionsContent: function termsAndConditionsContent() {
      return this.$store.getters[this.termsType + '/termsAndConditionsContent'];
    }
  },
  methods: {
    getTermsAndConditions: function getTermsAndConditions() {
      var _this2 = this;

      if (!this.termsAndConditionsContent) {
        this.loadingTerms = true;
        this.$store.dispatch(this.termsType + '/' + this.termsMethod).then(function () {
          _this2.loadingTerms = false;
        }).catch(function (err) {
          _this2.loadingTerms = false;

          _this2.handleError(err);
        });
      }
    }
  }
};