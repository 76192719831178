import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Axios from 'axios';
var state = {
  communicationAPIParams: {
    urlString: '/api/account/policy',
    jsonDataString: 'policyInfo',
    viewType: 'policy'
  },
  messagingAuthorizationKey: '',
  EDeliveryVerificationCode: '',
  pendingSMSPhoneNumber: '',
  pendingSMSVerification: false,
  showCodeHasBeenSent: false,
  showGetNewCode: false,
  showResendCode: false,
  showVerifyInput: false,
  showWrongCode: false,
  termsAndConditionsAccepted: 'notAccepted',
  thanksMessage: false
};
var getters = {
  communicationAPIParams: function communicationAPIParams(state) {
    return state.communicationAPIParams;
  },
  EDeliveryVerificationCode: function EDeliveryVerificationCode(state) {
    return state.EDeliveryVerificationCode;
  },
  messagingAuthorizationKey: function messagingAuthorizationKey(state) {
    return state.messagingAuthorizationKey;
  },
  pendingSMSPhoneNumber: function pendingSMSPhoneNumber(state) {
    return state.pendingSMSPhoneNumber;
  },
  pendingSMSVerification: function pendingSMSVerification(state) {
    return state.pendingSMSVerification;
  },
  showCodeHasBeenSent: function showCodeHasBeenSent(state) {
    return state.showCodeHasBeenSent;
  },
  showGetNewCode: function showGetNewCode(state) {
    return state.showGetNewCode;
  },
  showResendCode: function showResendCode(state) {
    return state.showResendCode;
  },
  showVerifyInput: function showVerifyInput(state) {
    return state.showVerifyInput;
  },
  showWrongCode: function showWrongCode(state) {
    return state.showWrongCode;
  },
  termsAndConditionsAccepted: function termsAndConditionsAccepted(state) {
    return state.termsAndConditionsAccepted;
  },
  thanksMessage: function thanksMessage(state) {
    return state.thanksMessage;
  }
};
var mutations = {
  clear_communication_module_state: function clear_communication_module_state(state) {
    state.EDeliveryVerificationCode = '';
    state.messagingAuthorizationKey = '';
    state.pendingSMSPhoneNumber = '';
    state.pendingSMSVerification = false;
    state.showCodeHasBeenSent = false;
    state.showGetNewCode = false;
    state.showResendCode = false;
    state.showVerifyInput = false;
    state.showWrongCode = false;
    state.termsAndConditionsAccepted = 'notAccepted';
    state.thanksMessage = false;
  },
  set_EDelivery_verificationCode: function set_EDelivery_verificationCode(state, value) {
    state.EDeliveryVerificationCode = value;
  },
  set_messaging_authorization_key: function set_messaging_authorization_key(state, value) {
    state.messagingAuthorizationKey = value;
    state.pendingSMSVerification = true;
    state.showGetNewCode = true;
    state.showVerifyInput = true;
  },
  set_pending_SMS_phone_number: function set_pending_SMS_phone_number(state, value) {
    state.pendingSMSPhoneNumber = value;
  },
  set_resent_verification_code: function set_resent_verification_code(state) {
    state.EDeliveryVerificationCode = '';
    state.showCodeHasBeenSent = true;
    state.showGetNewCode = false;
    state.showResendCode = false;
    state.showVerifyInput = true;
    state.showWrongCode = false;
  },
  set_terms_and_conditions_accepted: function set_terms_and_conditions_accepted(state, value) {
    state.termsAndConditionsAccepted = value;
  },
  set_thanks_message: function set_thanks_message(state, value) {
    state.thanksMessage = value;
  },
  update_verification_info: function update_verification_info(state, attemptAgain) {
    state.EDeliveryVerificationCode = '';
    state.showCodeHasBeenSent = false;
    state.showGetNewCode = false;
    state.showResendCode = !attemptAgain;
    state.showVerifyInput = attemptAgain;
    state.showWrongCode = attemptAgain;
  }
};
var actions = {
  deleteNonCommunicationInfo: function deleteNonCommunicationInfo(_ref) {
    var commit = _ref.commit;
    commit('billingAndPayments/clear_billingAndPayments_module_state', null, {
      root: true
    });
    commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false', null, {
      root: true
    });
    commit('clear_communication_module_state');
    commit('profile/clear_profile_module_state', null, {
      root: true
    });
    commit('documents/clear_documents_module_state', null, {
      root: true
    });
    commit('documents/set_doesDocumentsInfoExist_to_false', null, {
      root: true
    });
  },
  enrollEDeliverySMS: function enrollEDeliverySMS(_ref2, communicationsData) {
    var commit = _ref2.commit;
    var params = Object.keys(communicationsData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(communicationsData[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/policy/enroll-edelivery-sms', params).then(function (resp) {
        if (communicationsData.SMSPhoneNumber) {
          commit('set_messaging_authorization_key', resp.data.data.messagingAuthorizationKey);
          commit('set_pending_SMS_phone_number', communicationsData.SMSPhoneNumber);
        }

        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  processVerificationCode: function processVerificationCode(_ref3, verificationData) {
    var commit = _ref3.commit;
    var params = Object.keys(verificationData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(verificationData[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/policy/process-verification-code', params).then(function (resp) {
        if (resp.data.data.codeMatched) {
          commit('policy/set_EDeliverySMSPhoneNumber', verificationData.SMSPhoneNumber, {
            root: true
          });
          commit('clear_communication_module_state');
        } else {
          commit('update_verification_info', resp.data.data.attemptAgain);
        }

        resolve(resp.data.data.codeMatched);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  sendVerificationCode: function sendVerificationCode(_ref4, verificationData) {
    var commit = _ref4.commit;
    var params = Object.keys(verificationData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(verificationData[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/policy/send-verification-code', params).then(function (resp) {
        commit('set_messaging_authorization_key', resp.data.data.messagingAuthorizationKey);
        commit('set_pending_SMS_phone_number', verificationData.SMSPhoneNumber);
        commit('set_resent_verification_code');
        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  startOver: function startOver(_ref5) {
    var commit = _ref5.commit;
    commit('clear_communication_module_state');
  },
  unenrollEDeliverySMS: function unenrollEDeliverySMS(_ref6, communicationsData) {
    var commit = _ref6.commit;
    var params = Object.keys(communicationsData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(communicationsData[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/policy/unenroll-edelivery-sms', params).then(function (resp) {
        commit('clear_communication_module_state');
        commit('policy/set_EDeliverySMSPhoneNumber', '', {
          root: true
        });
        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  updateEDelivery: function updateEDelivery(_ref7, communicationsData) {
    var commit = _ref7.commit;
    var params = Object.keys(communicationsData).map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(communicationsData[key]);
    }).join('&');
    return new Promise(function (resolve, reject) {
      Axios.put('/api/account/policy/update-edelivery', params).then(function (resp) {
        if (communicationsData.SMSPhoneNumber) {
          commit('set_messaging_authorization_key', resp.data.data.messagingAuthorizationKey);
          commit('set_pending_SMS_phone_number', communicationsData.SMSPhoneNumber);
        }

        if (!communicationsData.EDeliverySelected) {
          commit('policy/set_EDeliverySMSPhoneNumber', '', {
            root: true
          });
        }

        resolve();
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  },
  updateEDeliveryVerificationCode: function updateEDeliveryVerificationCode(_ref8, value) {
    var commit = _ref8.commit;
    commit('set_EDelivery_verificationCode', value);
  },
  updateTermsAndConditionsAccepted: function updateTermsAndConditionsAccepted(_ref9, value) {
    var commit = _ref9.commit;
    commit('set_terms_and_conditions_accepted', value);
  },
  updateThanksMessage: function updateThanksMessage(_ref10, value) {
    var commit = _ref10.commit;
    commit('set_thanks_message', value);
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};