import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Axios from 'axios';
var state = {
  termsAndConditionsContent: ''
};
var getters = {
  termsAndConditionsContent: function termsAndConditionsContent(state) {
    return state.termsAndConditionsContent;
  }
};
var mutations = {
  set_termsAndConditions_data: function set_termsAndConditions_data(state, termsAndConditionsContent) {
    state.termsAndConditionsContent = termsAndConditionsContent;
  }
};
var actions = {
  getEDeliveryTermsAndConditions: function getEDeliveryTermsAndConditions(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      Axios.get('/api/pub/auth/edelivery-terms-and-conditions').then(function (resp) {
        var EDeliveryTermsAndConditions = resp.data.data.content;
        commit('set_termsAndConditions_data', EDeliveryTermsAndConditions);
        resolve(EDeliveryTermsAndConditions);
      }).catch(function (err) {
        if (err.response.status === 400) {
          var errorArray = [];
          err.response.data.data.errors.forEach(function (element) {
            return errorArray.push(element);
          });
          err.errorArray = errorArray;
        }

        reject(err);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};