import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var state = {
  doesProducerInfoExist: false,
  producerInfo: {
    zip: '',
    city: '',
    agencyName: '',
    state: '',
    producerName: '',
    address1: '',
    publicName: '',
    website: '',
    address2: '',
    workPhone: '',
    googleMapLink: ''
  },
  producerAPIParams: {
    urlString: '/api/account/policy/producer',
    jsonDataString: 'producerInfo',
    viewType: 'producer'
  }
};
var getters = {
  producerInfo: function producerInfo(state) {
    return state.producerInfo;
  },
  producerInfoExists: function producerInfoExists(state) {
    return state.doesProducerInfoExist;
  },
  producerAPIParams: function producerAPIParams(state) {
    return state.producerAPIParams;
  },
  producerWorkPhoneDigits: function producerWorkPhoneDigits(state) {
    return state.producerInfo.workPhone ? state.producerInfo.workPhone.replace(/\D/g, '') : '';
  }
};
var mutations = {
  set_producer_data: function set_producer_data(state, producerData) {
    state.producerInfo = producerData;
  },
  set_doesProducerInfoExist_to_true: function set_doesProducerInfoExist_to_true(state) {
    state.doesProducerInfoExist = true;
  },
  set_doesProducerInfoExist_to_false: function set_doesProducerInfoExist_to_false(state) {
    state.doesProducerInfoExist = false;
  },
  clear_producer_module_state: function clear_producer_module_state(state) {
    var p = state.producerInfo;
    p.zip = '';
    p.city = '';
    p.agencyName = '';
    p.state = '';
    p.producerName = '';
    p.address1 = '';
    p.publicName = '';
    p.website = '';
    p.address2 = '';
    p.workPhone = '';
    p.googleMapLink = '';
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};