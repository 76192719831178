import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
import PageHeader from '@/components/shared/ThePageHeader.vue';
export default {
  name: 'accountView',
  components: {
    pageHeader: PageHeader
  },
  computed: {
    isIdle: function isIdle() {
      return this.$store.state.idleVue.isIdle;
    }
  },
  mounted: function mounted() {
    if (this.$route.name === 'account') {
      this.$router.push({
        name: 'policy'
      });
    }
  },

  /*
    Idle-vue plugin provides us with a Vuex state variable we can monitor (state.idleVue) to determine if a user hasn't
    moved their mouse in 'x' milliseconds. Plugin is configured in main.js.
  */
  watch: {
    isIdle: function isIdle(userIsIdle, userWasIdle) {
      var _this = this;

      if (userIsIdle === true && userWasIdle === false) {
        this.$store.dispatch('authentication/logout').then(function () {
          _this.$router.push({
            name: 'login'
          });
        }).catch(function (err) {
          _this.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      }
    }
  }
};