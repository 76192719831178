import rg4js from 'raygun4js'

function initializeRaygun () {
  rg4js('apiKey', process.env.VUE_APP_RAYGUN_API_KEY)
  rg4js('enableCrashReporting', true)
  rg4js('enablePulse', true)
  rg4js('setVersion', '1.0.0')
}

function setUser (user) {
  rg4js('setUser', {
    identifier: user.accountKey,
    isAnonymous: false,
    email: user.email,
    fullName: user.firstName + ' ' + user.lastName
  })
}

function endSession () {
  rg4js('setUser', {
    isAnonymous: true
  })
}

function sendError (error, customData, tag) {
  rg4js('send', {
    error: error,
    customData: [customData],
    tags: ['vuejs', tag]
  })
}

function trackEvent (path) {
  rg4js('trackEvent', {
    type: 'pageView',
    path: path
  })
}

export { initializeRaygun, setUser, endSession, sendError, trackEvent, rg4js }
