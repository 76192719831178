import _objectSpread from "E:/Inetpub/wwwroot/onlinecustomerportal-vuejs/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { zipRegex, phoneRegex } from '@/common/validations';
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
export default {
  name: 'StripeBillingView',
  data: function data() {
    return {
      // Template flow control
      error: null,
      loading: false,
      showExpiryError: false,
      showSecurityCodeError: false,
      showCreditCardNumberError: false,
      // Stripe specific data
      stripe: '',
      elements: '',
      cardNumberElement: '',
      cardExpiryElement: '',
      cardCvcElement: '',
      // T&C data
      termsAndConditions: {
        status: 'notAccepted',
        show: false,
        displayError: false
      },
      // RPP form data
      form: {
        cardholderOnPolicyFlag: undefined,
        cardholderOnPolicyReason: '',
        cardholderName: '',
        cardholderAddress: '',
        cardholderAddress2: '',
        cardholderCity: '',
        cardholderState: '',
        cardholderZip: '',
        cardholderPhone: '',
        cardholderEmail: '',
        CCToken: '',
        cardholderCountry: 'US',
        cardholderEmailValid: '0',
        lastFourDigits: '0',
        cardExpireMonth: '',
        cardExpireYear: '',
        cardType: '',
        policyNumber: '',
        appID: '',
        userID: 0
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('RPP', ['RPPInfoExists', 'prefillCardholderAddress', 'prefillCardholderAddress2', 'prefillCardholderPhone', 'prefillCardholderEmail', 'prefillCardholderCity', 'prefillCardholderState', 'prefillCardholderZip', 'eTransVendor', 'stripeETransVendorParam', 'appID', 'RPPPullDate', 'RPPPrefillData', 'RP_StatusCode', 'RP_hasActiveNonRenewal', 'stateABBRS'])), {}, {
    signedUp: function signedUp() {
      // Need to know if user if form is for Edit or SignUp
      return this.$store.getters['billingAndPayments/RPPShowEdit'];
    }
  }),
  validations: {
    form: {
      cardholderOnPolicyFlag: {
        required: required
      },
      cardholderName: {
        required: required
      },
      cardholderAddress: {
        required: required
      },
      cardholderCity: {
        required: required
      },
      cardholderState: {
        required: required
      },
      cardholderZip: {
        required: required,
        zipRegex: zipRegex
      },
      cardholderPhone: {
        phoneRegex: phoneRegex
      },
      cardholderEmail: {
        email: email
      },
      cardholderOnPolicyReason: {
        required: requiredIf(function (form) {
          return this.form.cardholderOnPolicyFlag === '0';
        })
      }
    }
  },
  created: function created() {
    this.$store.dispatch('account/setSelectedTabName', 'Shared.Element.RPPSignup');
  },
  mounted: function mounted() {
    if (this.RPPInfoExists) {
      this.includeStripe('js.stripe.com/v3/', function () {
        this.configureStripe();
      }.bind(this));
      this.prefillForm();
    } else {
      this.$router.push({
        name: 'billingAndPayments'
      });
    }
  },
  methods: {
    saveRPPChange: function saveRPPChange() {
      this.error = null;
      this.$v.$reset();
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.error = 'Shared.Element.Errors.CorrectErrors';
        window.scrollTo(0, 0);
      } else {
        this.loading = true;

        if (!this.signedUp) {
          if (this.termsAndConditions.status !== 'accepted') {
            this.termsAndConditions.displayError = true;
            this.termsAndConditions.show = false;
            this.error = 'Shared.Element.Errors.TermsAndConditions';
            this.loading = false;
            window.scrollTo(0, 0);
          } else {
            this.termsAndConditions.displayError = false;
          }
        }

        if (!this.error) {
          if (!this.error) this.createStripeToken();
        } else {
          this.loading = false;
        }
      }
    },

    /*
      Stripe's createToken() method returns the token if OK, else an error.
      We pass it additional data for additional security validation. Returns a promise.
    */
    createStripeToken: function createStripeToken() {
      this.stripe.createToken(this.cardNumberElement, {
        'name': this.form.cardholderName,
        'address_line1': this.form.cardholderAddress,
        'address_city': this.form.cardholderCity,
        'address_state': this.form.cardholderState,
        'address_zip': this.form.cardholderZip
      }).then(this.handleOutCome);
    },
    handleOutCome: function handleOutCome(result) {
      var _this = this;

      if (result.token) {
        this.form.cardExpireMonth = result.token.card.exp_month;
        this.form.cardExpireYear = result.token.card.exp_year;
        this.form.lastFourDigits = result.token.card.last4;

        if (result.token.card.brand) {
          var brand = result.token.card.brand;
          if (brand === 'MasterCard') this.form.cardType = 'M';else if (brand === 'Visa') this.form.cardType = 'V';else if (brand === 'Discover') this.form.cardType = 'D';else this.form.cardType = '';
        }

        if (this.form.cardholderEmail) this.form.cardholderEmailValid = '1';
        this.form.CCToken = result.token.id;
        this.$store.dispatch('RPP/zeroDollarRPPAuthorization', this.form).then(function (resp) {
          if (resp) {
            _this.$store.commit('billingAndPayments/set_doesBillingAndPaymentsInfoExist_to_false');

            _this.$store.commit('billingAndPayments/clear_billingAndPayments_module_state');

            _this.$router.push({
              name: 'billingAndPayments',
              params: {
                RPPSuccessMessage: 'Shared.Element.InformationUpdated'
              }
            });
          }
        }).catch(function (err) {
          _this.handleError(err);
        });
      } else {
        var err = result.error.code;

        if (err === 'incomplete_number' || err === 'invalid_number') {
          this.showCreditCardNumberError = true;
        } else if (err === 'incomplete_expiry' || err === 'invalid_expiry_year' || err === 'invalid_expiry_year_past') {
          this.showExpiryError = true;
        } else if (err === 'incomplete_cvc') {
          this.showSecurityCodeError = true;
        }

        this.error = 'Shared.Element.Errors.CorrectErrors';
        this.loading = false;
        window.scrollTo(0, 0);
      }
    },
    includeStripe: function includeStripe(URL, callback) {
      var documentTag = document;
      var tag = 'script';
      var object = documentTag.createElement(tag);
      var scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;

      if (callback) {
        object.addEventListener('load', function (e) {
          callback(null, e);
        }, false);
        scriptTag.parentNode.insertBefore(object, scriptTag);
      }
    },
    configureStripe: function configureStripe() {
      var vm = this; // eslint-disable-line

      this.stripe = Stripe(this.$store.getters['RPP/stripeETransVendorParam']); // eslint-disable-line

      this.elements = this.stripe.elements();
      var style = {
        base: {
          fontWeight: 400,
          fontFamily: 'ralewaybold',
          color: '#000000',
          fontSize: '16px'
        },
        invalid: {
          color: '#FF0000'
        }
      };
      /*
        Stripe elements setup
        Create, mount to DOM, add onChange listener
      */
      // Credit card number

      this.cardNumberElement = this.elements.create('cardNumber', {
        style: style
      });
      this.cardNumberElement.mount('#stripe-card-number-element');
      this.cardNumberElement.on('change', function (e) {
        if (e.complete) {
          vm.showCreditCardNumberError = false;
        } else if (e.error && e.error.code !== 'incomplete_number') {
          vm.showCreditCardNumberError = true;
        }
      }); // Credit card expiry

      this.cardExpiryElement = this.elements.create('cardExpiry', {
        style: style
      });
      this.cardExpiryElement.mount('#stripe-card-expiry-element');
      this.cardExpiryElement.on('change', function (e) {
        if (e.complete) {
          vm.showExpiryError = false;
        } else if (e.error && e.error.code !== 'incomplete_expiry') {
          vm.showExpiryError = true;
        }
      }); // Credit card CV

      this.cardCvcElement = this.elements.create('cardCvc', {
        style: style
      });
      this.cardCvcElement.mount('#stripe-card-cv-element');
      this.cardCvcElement.on('change', function (e) {
        if (e.complete) {
          vm.showSecurityCodeError = false;
        } else if (e.error && e.error.code !== 'incomplete_cvc') {
          vm.showSecurityCodeError = true;
        }
      });
    },
    prefillForm: function prefillForm() {
      this.form.cardholderAddress = this.prefillCardholderAddress || '';
      this.form.cardholderAddress2 = this.prefillCardholderAddress2 || '';
      this.form.cardholderPhone = this.prefillCardholderPhone || '';
      this.form.cardholderEmail = this.prefillCardholderEmail || '';
      this.form.cardholderCity = this.prefillCardholderCity || '';
      this.form.cardholderState = this.prefillCardholderState || '';
      this.form.cardholderZip = this.prefillCardholderZip || '';
      this.form.policyNumber = this.$store.getters['account/selectedPolicyNumber'];
      this.form.appID = this.appID || '';
      this.form.userID = this.$store.getters['siteConfig/RPPUserID'];
    },
    handleError: function handleError(err) {
      var _this2 = this;

      if (err.response.status === 403) {
        this.loading = false;
        this.$store.dispatch('authentication/logout').then(function () {
          return _this2.$router.push({
            name: 'login',
            params: {
              externalError: err.errorArray[0]
            }
          });
        });
      } else if (err.response.status === 400 || err.response.status === 404 || err.response.status === 500) {
        this.loading = false;
        this.error = err.errorArray[0];
        this.alertError = true;
        window.scrollTo(0, 0);
      }
    }
  }
};