//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Driver',
  props: ['driver']
};